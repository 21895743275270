import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Row, Col, Button, Card, Tabs, Tag } from 'antd';
import { getDatabase, ref, child, get } from 'firebase/database';
import ProfileModal from './profile-details(Assess)';
import Header from '../Header';
import SideMenu from './SideMenu';

function sort_by_key(array, key) {
  if (array !== undefined) {
    return array.sort(function (a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return x < y ? 1 : x > y ? -1 : 0;
    });
  }
}

const { TabPane } = Tabs;
const dbRef = ref(getDatabase());

const AllCandidates = () => {
  const [loading, setLoading] = useState(false);
  const [profileId, setProfileId] = useState('');
  const [isModal, setModal] = useState(false);
  const [scored, setScored] = useState([]);
  const [unscored, setUnscored] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [job, setJob] = useState('');

  useEffect(() => {
    get(child(dbRef, `monkAdmins`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().includes(localStorage['accessTokenid']))
            setAdmin(true);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  async function fetchData() {
    let list = [];
    await get(child(dbRef, `quiz_taken_candidates`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((snap) => {
            snap.forEach((snap2) => {
              list.push(snap2.val());
            });
          });

          setScored(
            sort_by_key(
              list.filter((item) => item.totalScore !== ''),
              'date_applied'
            )
          );
          setUnscored(
            sort_by_key(
              list.filter((item) => item.totalScore === ''),
              'date_applied'
            )
          );
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const rerender = () => {
    setLoading(true);
    fetchData();
  };

  const columns = [
    {
      title: 'Name',
      key: 'profile',
      align: 'center',
      width: '20%',
      render: (text) => (
        <div>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <img
                src={text?.photo}
                style={{
                  borderRadius: '10px',
                  height: '35px',
                  display: 'inline',
                  marginRight: '10px',
                }}
                referrerpolicy='no-referrer'
              />
            </div>
            <div style={{ textAlign: 'left' }}>
              <div>{text?.name}</div>
            </div>
          </Row>
        </div>
      ),
    },
    {
      title: 'Date Applied',
      key: 'date',
      align: 'center',
      width: '10%',
      dataIndex: 'date_applied',
      render: (dat) => (
        <div style={{ textAlign: 'center' }}>
          {new Date(dat).toLocaleDateString('en-IN')}
        </div>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      align: 'center',
      dataIndex: 'email',
      width: '20%',
      render: (current_company) => (
        <div style={{ textAlign: 'left' }}>{current_company}</div>
      ),
    },
    {
      title: 'Company Applied For',
      key: 'company',
      align: 'center',
      dataIndex: 'company_name',
      width: '15%',
      render: (current_company) => (
        <div>{current_company ? current_company : '--'}</div>
      ),
    },
    {
      title: 'Score',
      key: 'score',
      align: 'center',
      width: '10%',
      render: (title) => (
        <div style={{ textAlign: 'center' }}>
          {title?.totalScore !== ''
            ? `${title?.totalScore}/${title?.maxScore}`
            : '-'}
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      dataIndex: 'status',
      width: '10%',
      render: (title) => (
        <div style={{ textAlign: 'center' }}>{title ? title : '-'}</div>
      ),
    },
    {
      title: 'View Assessment',
      key: 'view',
      align: 'center',
      width: '15%',
      render: (text) => (
        <div>
          {text ? (
            <Button
              onClick={() => {
                setJob(text?.job_id);
                setProfileId(text?.uid);
                setModal(true);
              }}>
              Details
            </Button>
          ) : (
            '--'
          )}
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      {localStorage['accessTokenid'] && admin ? (
        <div>
          <SideMenu selectedKey={'2'} />
          <div style={{ marginLeft: '200px' }}>
            <Header />
            <div style={{ padding: '10px' }}>
              {isModal ? (
                <ProfileModal
                  visible={isModal}
                  id={profileId}
                  jobid={job}
                  rerender={rerender}
                  closeModal={() => {
                    setModal(false);
                  }}
                />
              ) : null}
              <Row>
                <Col
                  span={24}
                  style={{
                    fontSize: 30,
                    fontWeight: '550',
                    paddingLeft: '5px',
                    marginBottom: '20px',
                  }}>
                  All Candidates
                </Col>
              </Row>

              <div className='tabs-style'>
                <Tabs defaultActiveKey='1'>
                  <TabPane tab='Scoring Pending' key='1'>
                    <div className='card-root'>
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          marginBottom: '30px',
                        }}>
                        <b>Total Candidates: {unscored?.length}</b>
                        <p></p>
                        <Row gutter={[24, 24]}>
                          <Col span={24} style={{ height: 'auto' }}>
                            <Table
                              size='small'
                              style={{ marginRight: '15px' }}
                              loading={loading}
                              columns={columns}
                              dataSource={unscored}
                              //   pagination={{
                              //     current: monk_profiles?.pageNumber,
                              //     pageSize: monk_profiles?.pageSize,
                              //     total: monk_profiles?.total_length,
                              //     showTotal: handleShowTotal,
                              //   }}
                              //   onChange={handlePagination}
                              rowKey={'uid'}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab='Scored' key='2'>
                    <div className='card-root'>
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          marginBottom: '30px',
                        }}>
                        <b>Total Candidates: {scored?.length}</b>
                        <p></p>
                        <Row gutter={[24, 24]}>
                          <Col span={24} style={{ height: 'auto' }}>
                            <Table
                              size='small'
                              style={{ marginRight: '15px' }}
                              loading={loading}
                              columns={columns}
                              dataSource={scored}
                              //   pagination={{
                              //     current: monk_profiles?.pageNumber,
                              //     pageSize: monk_profiles?.pageSize,
                              //     total: monk_profiles?.total_length,
                              //     showTotal: handleShowTotal,
                              //   }}
                              //   onChange={handlePagination}
                              rowKey={'id'}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      ) : (
        'You are not an admin'
      )}
    </Wrapper>
  );
};

export default AllCandidates;

const Wrapper = styled.div`
    background-color: #fff;
    width: 99vw;
    overflow: hidden;
    .ant-tag{
        margin-top: 7px;
    }
    .ant-drawer {
        transform: none !important;
    }
    .ant-card-bordered {
      border: none;
    }
    .ant-card-body {
      padding: 0px;
    }
    .ant-main {
        padding: 10px;
    }
    .ant-main {
        width: 260px;
        height: auto;
    }
    .ant-tabs-tab-active {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        transition: 0.15s all;
    }
    .ant-tabs-tab-active .ant-card-meta-title {
        color: #b32800 !important;
    }
    .ant-tabs-tab-active .ant-main {
        box-shadow: 0px -2px 30px -15px rgba(0, 0, 0, 0.3) inset;
    }
    .tabs-style .ant-tabs-ink-bar {
        background: #b32800 !important;
        height: 3px !important;
        border-radius: 16px !important;
    }
    p {
        margin-top: 7px;
        margin-bottom: 0px !important;
        color: gray;
        font-weight: 400;
    }
    .container {
      margin-left: 220px;
      height :100%;
      padding-bottom: 50px;
    }
  }
`;
