import React from 'react';

const Contact = () => {
  return (
    <i style={{ fontSize: '18px', marginLeft: '20px' }}>
      <span>
        Are you facing any technical glitch?{' '}
        <a href='https://api.whatsapp.com/send/?phone=917996496999&text&type=phone_number&app_absent=0' target='_blank'
          rel='noopener noreferrer'
        >
          Chat with us
        </a>
      </span>
    </i>
  );
};

export default Contact;
