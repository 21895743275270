import React, { useEffect, useState } from 'react';
import { Drawer, Spin, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { getDatabase, ref, child, get, set } from 'firebase/database';
import { CloseCircleFilled } from '@ant-design/icons';
import { database } from '../../config/fire';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const dbRef = ref(getDatabase());

const ProfileModal = (props) => {
  const { visible, closeModal, jobid, id, rerender } = props;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState();
  const [status, setStatus] = useState('');
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await get(child(dbRef, `quiz_taken_candidates/${jobid}/${id}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setData(snapshot.val());

            let list = {};
            for (let i = 0; i < snapshot.val().answers.length; i++) {
              list['score' + i] =
                snapshot.val().answers[i].score === -1
                  ? ''
                  : snapshot.val().answers[i].score;
            }

            setScore(list);
          } else {
            console.log('No data available');
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setLoading(false);
      setReload(false);
    }
    fetchData();
  }, [id, reload]);

  const handleEdit = () => {
    let ob = {};
    for (let i = 0; i < data?.answers.length; i++) {
      ob['score' + i] = data?.answers[i].score;
    }

    setScore(ob);
    setStatus(data.status);
    setEdit(true);
  };

  const handleChange = (event) => {
    setScore({
      ...score,
      [event.target.name]: event.target.value,
    });
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const addScore = async () => {
    let len = Object.keys(score).length;
    let total = 0;

    for (var i = 0; i < len; i++) {
      total = total + (parseFloat(score['score' + i]) || 0);

      await set(
        ref(
          database,
          `quiz_taken_candidates/${jobid}/${id}/answers/${i}/score`
        ),
        parseFloat(score['score' + i] || 0)
      );
    }

    await set(
      ref(database, `quiz_taken_candidates/${jobid}/${id}/totalScore`),
      total
    );

    await set(
      ref(database, `quiz_taken_candidates/${jobid}/${id}/status`),
      status
    );

    await set(ref(database, `jobs-applied/${id}/${jobid}/status`), status);

    setEdit(false);
    rerender();
    setReload(true);
  };

  return (
    <Wrapper>
      <div>
        <Drawer
          placement='right'
          closable={true}
          visible={visible}
          width='50%'
          destroyOnClose={true}
          onClose={closeModal}
          closeIcon={<CloseCircleFilled />}
          getContainer={false}
          footer={null}
          maskClosable={true}>
          <Spin spinning={loading}>
            <div className='top-quiz-title'>
              <h2>Assessment Response</h2>
              {data?.totalScore !== '' ? (
                <h4>
                  Score : {data?.totalScore}/25{' '}
                  <span>
                    <Button type='primary' onClick={handleEdit}>
                      Edit
                    </Button>
                  </span>
                </h4>
              ) : (
                <h4>Scores Awaited</h4>
              )}
            </div>
            {data ? (
              <Grid container>
                <Grid item xs={12}>
                  <div align='center' className='qna'>
                    <div className='show-all'>
                      {data?.questions?.map((info, i) => {
                        if (info?.correct_answer[0] === '')
                          return (
                            <div key={i} className='pb-6'>
                              <p className='show-ques'>
                                Q. {info.question}{' '}
                                <b className='ques-tags'>
                                  (Category: {info.tag})
                                </b>
                              </p>
                              {info.media !== '' &&
                              info.media !== undefined &&
                              info.media !== null ? (
                                <img src={info.media} alt={info.question} />
                              ) : null}
                              {info.info !== '' &&
                              info.info !== undefined &&
                              info.info !== null &&
                              info.info.length !== 0 ? (
                                <div>
                                  <ul>
                                    {info.info.map((info, k) => {
                                      return <li key={k}>{info}</li>;
                                    })}
                                  </ul>
                                </div>
                              ) : null}
                              {info.qualification !== '' &&
                              info.qualification !== undefined &&
                              info.qualification !== null &&
                              info.qualification.length !== 0 ? (
                                <div>
                                  <p>Basic Qualifications</p>
                                  <ul>
                                    {info.qualification.map((info, k) => {
                                      return <li key={k}>{info}</li>;
                                    })}
                                  </ul>
                                </div>
                              ) : null}
                              {info.options !== '' &&
                              info.options !== undefined &&
                              info.options !== null &&
                              info.options.length !== 0 ? (
                                <div>
                                  <strong>
                                    <ul>
                                      {info.options.map((info, k) => {
                                        return <li key={k}>{info}</li>;
                                      })}
                                    </ul>
                                  </strong>
                                </div>
                              ) : null}
                              <div className='quiz-ans'>
                                <b>Ans.&nbsp;&nbsp;&nbsp;</b>
                                {data.answers[i]?.answer !== '' ? (
                                  data.answers[i]?.answer
                                ) : (
                                  <span className='font-serif text-red-600'>
                                    NOT ANSWERED
                                  </span>
                                )}
                              </div>

                              <div className='scoring'>
                                {data.answers[i]?.score === -1 || edit ? (
                                  <Input.Group
                                    compact
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}>
                                    <Input
                                      size='small'
                                      name={'score' + i}
                                      type='number'
                                      value={score['score' + i]}
                                      style={{
                                        width: '90px',
                                      }}
                                      onChange={handleChange}
                                      placeholder='Type Score'
                                    />
                                  </Input.Group>
                                ) : (
                                  <p>
                                    <b>Score: {data.answers[i]?.score}</b>
                                  </p>
                                )}
                                <p>
                                  <b>
                                    Max Question Score:{' '}
                                    {data.questions[i]?.maxScore}
                                  </b>
                                </p>
                              </div>
                            </div>
                          );
                      })}
                      <br />
                      <Grid container spacing={4}>
                        {data.status === '' || edit ? (
                          <Grid item xs={12} md={4}>
                            <FormControl size='small' fullWidth>
                              <InputLabel>Status</InputLabel>
                              <Select
                                value={status}
                                size='small'
                                label='Status'
                                onChange={handleStatus}>
                                <MenuItem value='Good'>Good</MenuItem>
                                <MenuItem value='Above Average'>
                                  Above Average
                                </MenuItem>
                                <MenuItem value='Below Average'>
                                  Below Average
                                </MenuItem>
                                <MenuItem value='Poor'>Poor</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={4}>
                            <b>Status: {data.status}</b>
                          </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                          <Button
                            type='primary'
                            size='large'
                            onClick={addScore}>
                            <PlusOutlined /> Submit Scores
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </Spin>
        </Drawer>
      </div>
    </Wrapper>
  );
};

ProfileModal.propTypes = {
  visible: PropTypes.any,
  id: PropTypes.any,
  jobid: PropTypes.any,
  closeModal: PropTypes.func,
  rerender: PropTypes.func,
};

export default ProfileModal;

const Wrapper = styled.div`
  background-color: #fff;
  .ant-drawer-header-title {
    justify-content: flex-end;
  }
  .ant-drawer-close {
    margin-right: 0px;
  }
  p {
    margin-bottom: 4px;
    white-space: nowrap;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
