import React, { useEffect } from 'react';
import Header from './Header';
import { Grid, Button, Dialog, DialogContent, Checkbox } from '@mui/material';
import PostJob from './Jobs/PostJob';
import { set } from 'firebase/database';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ShowJobs from './Jobs/ShowJobs';
import { auth } from '../config/fire';
import { Form, InputNumber, Radio, Select, message } from 'antd';
import { child, get, getDatabase, ref as dref, } from 'firebase/database';
import { getStorage, uploadBytes, ref as sref } from 'firebase/storage';
import { cities } from './Jobs/Cities';
import { Option } from 'antd/lib/mentions';

const dbRef = dref(getDatabase());
const Main = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = React.useState(false);

  const [resume, setResume] = React.useState('');
  const [prevresume, setPrevResume] = React.useState('');
  const [fileSelected, setFileSelected] = React.useState(false);

  const [checked, setChecked] = React.useState(true);

  const toggleChecked = () => {
    setChecked(!checked);
  };

  const [data, setData] = React.useState({});
  const [admin, setAdmin] = React.useState(false);
  const [form] = Form.useForm();

  useEffect(() => {

    get(child(dbRef, `monkAdmins`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().includes(localStorage['accessTokenid']))
            setAdmin(true);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (localStorage['provider'] !== 'Recruiter' && !admin && !localStorage['jobs']) {
      auth.onAuthStateChanged((user) => {
        if (user && localStorage['provider'] !== 'Recruiter') {
          setShowModal(true);
        }
        else {
          setShowModal(false);
        }
      });
    }
  }, [admin]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && localStorage['provider'] !== 'Recruiter') {
        get(child(dbRef, `user_filters/${localStorage['accessTokenid']}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              setShowModal(false);
            } else {
              setShowModal(true);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    get(child(dbRef, `candidates_info/${localStorage['accessTokenid']}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setPrevResume(snapshot.val().resume);
        } else {
          console.log('No data available');
          setPrevResume(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const closeModal = (e, reason) => {
    if (reason && reason === "backdropClick") {
      return; // disable clicking on backdrop to close modal
    }

    setShowModal(false);
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];

    const storage = getStorage();

    await uploadBytes(
      sref(storage, localStorage['accessTokenid'] + '/' + file.name),
      file
    ).then((snapshot) => {
      setResume(
        'https://firebasestorage.googleapis.com/v0/b/hiring-monk.appspot.com/o/' +
        encodeURIComponent(snapshot.metadata.fullPath) +
        '?alt=media'
      );
    });

    setFileSelected(true);
  };


  const handleFormSubmit = async (values) => {
    setData(values);

    await set(child(dbRef, `user_filters/${localStorage['accessTokenid']}`), {
      location: values.location,
      resume: resume !== '' && fileSelected ? resume : prevresume,
      current_salary: values.salary,
      expected_salary: values.expected_salary,
      experience: values.experience,
      notice: values.notice_period,
      experience_type: values.experience_type,
      us_domestic: values.us_domestic,
      auto_apply: checked,
    }).then(() => {
      window.location.reload();
    }).catch((error) => {
      message.error('Failed to save your preferences');
    });

    closeModal();
  };


  return (
    <>
      <Header />

      <Dialog
        open={showModal}
        onClose={closeModal}
        aria-labelledby="user-details"
        maxWidth='sm'
        disableEscapeKeyDown
        style={{ zIndex: 20 }}
      >
        <DialogContent>
          <Grid >
            <Grid item xs={12}>
              <h3>
                <b>Enter your details to continue</b>
              </h3>
              <br />
              <Form
                form={form}
                onFinish={handleFormSubmit}
              >

                <Form.Item
                  label='Enter your preferred Location'
                  name='location'
                  rules={[
                    {
                      required: true,
                      message: 'Please fill this field',
                    },
                  ]}>
                  <Select mode='multiple'
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    {cities.map((info) => {
                      return <Option key={info}>{info}</Option>;
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label='Resume'
                  name='resume'
                  rules={[
                    {
                      required: !prevresume && !resume ? true : false,
                      message: 'This field is required',
                    },
                  ]}>
                  <input accept='.pdf' type='file' onChange={handleUpload} />
                  {prevresume ? (
                    <div style={{
                      display: 'flex',

                      gap: '10px',
                    }}>
                      <a href={prevresume} target='_blank' rel="noreferrer">
                        Your Previous Upload
                      </a>
                      <div onClick={() => setPrevResume('')} style={{
                        cursor: 'pointer',
                        color: 'red',

                      }}>
                        X
                      </div>
                    </div>
                  ) : null}
                </Form.Item>

                <Form.Item
                  label="Enter your Current Salary (lacs)"
                  name='salary'
                  rules={[
                    {
                      required: true,
                      message: 'Please fill this field',
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="Enter your Expected Salary (lacs)"
                  name='expected_salary'
                  rules={[
                    {
                      required: true,
                      message: 'Please fill this field',
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="Enter your notice period (in days)"
                  name='notice_period'
                  rules={[
                    {
                      required: true,
                      message: 'Please fill this field',
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item label="Enter your Experience years" name='experience' rules={[
                  {
                    required: true,
                    message: 'Please fill this field',
                  }
                ]}>
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label='Experience in Domestic or US Staffing?'
                  name='us_domestic'
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}>
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value='Domestic'>Domestic</Radio.Button>
                    <Radio.Button value='US'>US</Radio.Button>
                    <Radio.Button value='Both'>Both</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label='Role Experience'
                  name='experience_type'
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}>
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value='Contract'>Contract Staffing</Radio.Button>
                    <Radio.Button value='Permanent'>Permanent Hiring</Radio.Button>
                    <Radio.Button value='In-House TA'>In-House TA</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name='auto_apply'
                  valuePropName='checked'
                >
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Checkbox size='small'
                      checked={checked}
                      onChange={toggleChecked}
                      defaultChecked={true}
                    />
                    <p style={{ margin: '0px' }}>
                      Automatically Apply for any future jobs matching my profile.
                    </p>
                  </Grid>
                </Form.Item>

                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>

              </Form>
            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>


      <Grid container style={{ backgroundColor: 'black', padding: '30px 0px' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '54px', color: 'white', fontWeight: '700' }}>
            India's Best
          </span>
          <br />
          <span
            style={{ fontSize: '54px', color: '#b32800', fontWeight: '700' }}>
            Recruiter Jobs
          </span>
          <br />
          <br />
          <span style={{ fontSize: '20px', color: 'white' }}>
            It is a curated job board for tech recruiters
          </span>
          <br />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            className='round white-button'
            onClick={() => {
              document.body.scrollTop =
                document.documentElement.scrollTop = 400;
            }}>
            Browse All Jobs
          </Button>
          {localStorage['accessTokenid'] ? (
            <Link to='/postjob' className='links'>
              <Button className='round grad-button' variant='contained'>
                Post A Job
              </Button>
            </Link>
          ) : (
            <PostJob />
          )}
        </Grid>
      </Grid>
      {localStorage['accessTokenid'] &&
        localStorage['provider'] === 'Recruiter' ? (
        <Button
          variant='contained'
          style={{ float: 'right', margin: '10px' }}
          onClick={() => navigate('/vacancies')}>
          Go To Dashboard
        </Button>
      ) : (
        ''
      )}

      {localStorage['accessTokenid'] &&
        localStorage['provider'] !== 'Recruiter' ? (
        <Button
          variant='contained'
          style={{ float: 'right', margin: '10px' }}
          onClick={() => navigate('/jobs-applied')}>
          Go To Dashboard
        </Button>
      ) : (
        ''
      )}

      <ShowJobs id='contact' filters={data} />
    </>
  );
};

export default Main;
