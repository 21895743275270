import React from 'react';
import Wrapper from './SideMenu.style';
import { Menu, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import {
  CreditCardOutlined,
  ControlOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { PeopleAltOutlined } from '@mui/icons-material';

const auth = getAuth();

const SideMenu = (props) => {
  const { selectedKey } = props;
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Row style={{ height: '40px' }}>
        <Col span={24}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: '500',
              marginLeft: '10px',
              marginTop: '9px',
            }}>
            Dashboard
          </p>
        </Col>
      </Row>

      <Menu
        style={{
          width: 200,
          height: '100%',
          paddingTop: 20,
          background: '#fafaff',
        }}
        defaultSelectedKeys={[selectedKey]}
        mode='inline'>
        {routes.map((route, index) => {
          return (
            <Menu.Item
              style={{
                fontSize: '14px',
                fontWeight: 600,
                paddingLeft: '10px',
              }}
              className='menu-item'
              icon={route.icon}
              onClick={() => {
                navigate(route.path);
              }}
              key={index + 1}>
              {route.name}
            </Menu.Item>
          );
        })}

        <Menu.Item
          onClick={async () => {
            localStorage.removeItem('accessTokenid');
            localStorage.removeItem('provider');
            await signOut(auth);
            navigate(`/`);
          }}
          className='menu-item'
          style={{ fontSize: '14px', fontWeight: 600, paddingLeft: '10px' }}
          icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </Wrapper>
  );
};

export default SideMenu;

const routes = [
  // {
  //   path: '/create-vacancy',
  //   name: 'Create Vacancy',
  //   icon: <CreditCardOutlined />,
  // },
  // {
  //   path: '/monk-candidates',
  //   name: 'Applied Via Monk',
  //   icon: <PersonSearchIcon />,
  // },
  {
    path: '/admin',
    name: 'Quiz Taken Candidates',
    icon: <ControlOutlined />,
  },
  {
    path: '/all-candidates',
    name: 'Candidates',
    icon: <PersonSearchIcon />,
  },
  {
    path: '/admin-monk-candidates',
    name: 'Monk Applied',
    icon: <PersonSearchIcon />,
  },
  {
    path: '/create-hackathon',
    name: 'Create Hackathon',
    icon: <PeopleAltOutlined />
  }
  //   {
  //     path: '/profiles-visited',
  //     name: 'Profiles Visited',
  //     icon: <UserOutlined />,
  //   },
];
