export const cities = [
  'Adilabad',
  'Anantapur',
  'Bengaluru',
  'Chittoor',
  'Kakinada',
  'Guntur',
  'Karimnagar',
  'Khammam',
  'Krishna',
  'Kurnool',
  'Mahbubnagar',
  'Medak',
  'Nalgonda',
  'Nizamabad',
  'Ongole',
  'Hyderabad',
  'Srikakulam',
  'Nellore',
  'Visakhapatnam',
  'Vizianagaram',
  'Warangal',
  'Secunderabad',
  'Eluru',
  'Kadapa',
  'Baksa',
  'Barpeta',
  'Bongaigaon',
  'Cachar',
  'Chirang',
  'Darrang',
  'Dhemaji',
  'Dima Hasao',
  'Dhubri',
  'Dibrugarh',
  'Goalpara',
  'Golaghat',
  'Hailakandi',
  'Jorhat',
  'Kamrup',
  'Kamrup Metropolitan',
  'Karbi Anglong',
  'Karimganj',
  'Kokrajhar',
  'Lakhimpur',
  'Marigaon',
  'Nagaon',
  'Nalbari',
  'Sibsagar',
  'Sonitpur',
  'Tinsukia',
  'Udalguri',
  'Araria',
  'Arwal',
  'Aurangabad',
  'Banka',
  'Begusarai',
  'Bhagalpur',
  'Bhojpur',
  'Buxar',
  'Darbhanga',
  'Champaran',
  'Gaya',
  'Gopalganj',
  'Jamui',
  'Jehanabad',
  'Kaimur',
  'Katihar',
  'Khagaria',
  'Kishanganj',
  'Lakhisarai',
  'Madhepura',
  'Madhubani',
  'Munger',
  'Muzaffarpur',
  'Nalanda',
  'Nawada',
  'Patna',
  'Purnia',
  'Rohtas',
  'Saharsa',
  'Samastipur',
  'Saran',
  'Sheikhpura',
  'Sheohar',
  'Sitamarhi',
  'Siwan',
  'Supaul',
  'Vaishali',
  'Chandigarh',
  'Bastar',
  'Bijapur',
  'Bilaspur',
  'Dantewada',
  'Dhamtari',
  'Durg',
  'Jashpur',
  'Janjgir-Champa',
  'Korba',
  'Koriya',
  'Kanker',
  'Kabirdham',
  'Mahasamund',
  'Narayanpur',
  'Raigarh',
  'Rajnandgaon',
  'Raipur',
  'Surguja',
  'Dadra and Nagar Haveli',
  'Daman',
  'Diu',
  'Delhi',
  'New Delhi',
  'Goa',
  'Ahmedabad',
  'Amreli district',
  'Anand',
  'Banaskantha',
  'Bharuch',
  'Bhavnagar',
  'Dahod',
  'The Dangs',
  'Gandhinagar',
  'Jamnagar',
  'Junagadh',
  'Kutch',
  'Kheda',
  'Mehsana',
  'Narmada',
  'Navsari',
  'Patan',
  'Panchmahal',
  'Porbandar',
  'Rajkot',
  'Sabarkantha',
  'Surendranagar',
  'Surat',
  'Vyara',
  'Vadodara',
  'Valsad',
  'Ambala',
  'Bhiwani',
  'Faridabad',
  'Fatehabad',
  'Gurgaon',
  'Hissar',
  'Jhajjar',
  'Jind',
  'Karnal',
  'Kaithal',
  'Kurukshetra',
  'Mahendragarh',
  'Mewat',
  'Palwal',
  'Panchkula',
  'Panipat',
  'Rewari',
  'Rohtak',
  'Sirsa',
  'Sonipat',
  'Yamunanagar',
  'Chamba',
  'Hamirpur',
  'Kangra',
  'Kinnaur',
  'Kullu',
  'Lahaul and Spiti',
  'Mandi',
  'Shimla',
  'Sirmaur',
  'Solan',
  'Una',
  'Anantnag',
  'Badgam',
  'Bandipora',
  'Baramulla',
  'Doda',
  'Ganderbal',
  'Jammu',
  'Kargil',
  'Kathua',
  'Kishtwar',
  'Kupwara',
  'Kulgam',
  'Leh',
  'Poonch',
  'Pulwama',
  'Rajauri',
  'Ramban',
  'Reasi',
  'Samba',
  'Shopian',
  'Srinagar',
  'Udhampur',
  'Bokaro',
  'Chatra',
  'Deoghar',
  'Dhanbad',
  'Dumka',
  'Singhbhum',
  'Garhwa',
  'Giridih',
  'Godda',
  'Gumla',
  'Hazaribag',
  'Jamtara',
  'Khunti',
  'Koderma',
  'Latehar',
  'Lohardaga',
  'Pakur',
  'Palamu',
  'Ramgarh',
  'Ranchi',
  'Sahibganj',
  'Seraikela Kharsawan',
  'Simdega',
  'Bagalkot',
  'Bangalore',
  'Belgaum',
  'Bellary',
  'Bidar',
  'Chamarajnagar',
  'Chikkamagaluru',
  'Chikkaballapur',
  'Chitradurga',
  'Davanagere',
  'Dharwad',
  'Kannada',
  'Gadag',
  'Gulbarga',
  'Hassan',
  'Haveri district',
  'Kodagu',
  'Kolar',
  'Koppal',
  'Mandya',
  'Mysore',
  'Raichur',
  'Shimoga',
  'Tumkur',
  'Udupi',
  'Ramanagara',
  'Yadgir',
  'Alappuzha',
  'Ernakulam',
  'Idukki',
  'Kannur',
  'Kasaragod',
  'Kollam',
  'Kottayam',
  'Kozhikode',
  'Malappuram',
  'Palakkad',
  'Pathanamthitta',
  'Thrissur',
  'Thiruvananthapuram',
  'Trivandrum',
  'Telangana',
  'Wayanad',
  'Yangon',
  'Alirajpur',
  'Anuppur',
  'Ashok Nagar',
  'Balaghat',
  'Barwani',
  'Betul',
  'Bhind',
  'Bhopal',
  'Burhanpur',
  'Chhatarpur',
  'Chhindwara',
  'Damoh',
  'Datia',
  'Dewas',
  'Dhar',
  'Dindori',
  'Guna',
  'Gwalior',
  'Harda',
  'Hoshangabad',
  'Indore',
  'Jabalpur',
  'Jhabua',
  'Katni',
  'Mandla',
  'Mandsaur',
  'Morena',
  'Narsinghpur',
  'Neemuch',
  'Panna',
  'Rewa',
  'Rajgarh',
  'Ratlam',
  'Raisen',
  'Sagar',
  'Satna',
  'Sehore',
  'Seoni',
  'Shahdol',
  'Shajapur',
  'Sheopur',
  'Shivpuri',
  'Sidhi',
  'Singrauli',
  'Tikamgarh',
  'Ujjain',
  'Umaria',
  'Vidisha',
  'Ahmednagar',
  'Akola',
  'Amravati',
  'Bhandara',
  'Beed',
  'Buldhana',
  'Chandrapur',
  'Dhule',
  'Gadchiroli',
  'Gondia',
  'Hingoli',
  'Jalgaon',
  'Jalna',
  'Kolhapur',
  'Latur',
  'Mumbai City',
  'Mumbai suburban',
  'Nandurbar',
  'Nanded',
  'Nagpur',
  'Nashik',
  'Osmanabad',
  'Parbhani',
  'Pune',
  'Raigad',
  'Ratnagiri',
  'Sindhudurg',
  'Sangli',
  'Solapur',
  'Satara',
  'Thane',
  'Wardha',
  'Washim',
  'Yavatmal',
  'Bishnupur',
  'Churachandpur',
  'Chandel',
  'Senapati',
  'Tamenglong',
  'Thoubal',
  'Ukhrul',
  'Imphal',
  'Jaintia Hills',
  'Ri Bhoi',
  'Aizawl',
  'Champhai',
  'Kolasib',
  'Lawngtlai',
  'Lunglei',
  'Mamit',
  'Saiha',
  'Serchhip',
  'Dimapur',
  'Kohima',
  'Mokokchung',
  'Mon',
  'Phek',
  'Tuensang',
  'Wokha',
  'Zunheboto',
  'Angul',
  'Boudh',
  'Bauda',
  'Bhadrak',
  'Balangir',
  'Bargarh',
  'Baragarh',
  'Balasore',
  'Cuttack',
  'Debagarh',
  'Deogarh',
  'Dhenkanal',
  'Ganjam',
  'Gajapati',
  'Jharsuguda',
  'Jajpur',
  'Jagatsinghpur',
  'Khordha',
  'Kendujhar',
  'Keonjhar',
  'Kalahandi',
  'Kandhamal',
  'Koraput',
  'Kendrapara',
  'Malkangiri',
  'Mayurbhanj',
  'Nabarangpur',
  'Nuapada',
  'Nayagarh',
  'Puri',
  'Rayagada',
  'Sambalpur',
  'Subarnapur',
  'Sonepur',
  'Sundergarh',
  'Karaikal',
  'Mahe',
  'Pondicherry',
  'Yanam',
  'Amritsar',
  'Barnala',
  'Bathinda',
  'Firozpur',
  'Faridkot',
  'Fatehgarh Sahib',
  'Fazilka',
  'Gurdaspur',
  'Hoshiarpur',
  'Jalandhar',
  'Kapurthala',
  'Ludhiana',
  'Mansa',
  'Moga',
  'Sri Muktsar Sahib',
  'Pathankot',
  'Patiala',
  'Rupnagar',
  'Ajitgarh',
  'Mohali',
  'SAS Nagar',
  'Sangrur',
  'Nawanshahr',
  'Tarn Taran',
  'Ajmer',
  'Alwar',
  'Bikaner',
  'Barmer',
  'Banswara',
  'Bharatpur',
  'Baran',
  'Bundi',
  'Bhilwara',
  'Churu',
  'Chittorgarh',
  'Dausa',
  'Dholpur',
  'Dungapur',
  'Ganganagar',
  'Hanumangarh',
  'Jhunjhunu',
  'Jalore',
  'Jodhpur',
  'Jaipur',
  'Jaisalmer',
  'Jhalawar',
  'Karauli',
  'Kota',
  'Nagaur',
  'Pali',
  'Pratapgarh',
  'Rajsamand',
  'Sikar',
  'Sawai Madhopur',
  'Sirohi',
  'Tonk',
  'Udaipur',
  'Sikkim',
  'Ariyalur',
  'Chennai',
  'Coimbatore',
  'Cuddalore',
  'Dharmapuri',
  'Dindigul',
  'Erode',
  'Kanchipuram',
  'Kanyakumari',
  'Karur',
  'Madurai',
  'Nagapattinam',
  'Nilgiris',
  'Namakkal',
  'Perambalur',
  'Pudukkottai',
  'Ramanathapuram',
  'Salem',
  'Sivaganga',
  'Tirupur',
  'Tiruchirappalli',
  'Theni',
  'Tirunelveli',
  'Thanjavur',
  'Thoothukudi',
  'Tiruvallur',
  'Tiruvarur',
  'Tiruvannamalai',
  'Vellore',
  'Viluppuram',
  'Virudhunagar',
  'Dhalai',
  'Khowai',
  'Tripura',
  'Agra',
  'Allahabad',
  'Aligarh',
  'Ambedkar Nagar',
  'Auraiya',
  'Azamgarh',
  'Barabanki',
  'Budaun',
  'Bagpat',
  'Bahraich',
  'Bijnor',
  'Ballia',
  'Banda',
  'Balrampur',
  'Bareilly',
  'Basti',
  'Bulandshahr',
  'Chandauli',
  'Chhatrapati Shahuji Maharaj Nagar',
  'Chitrakoot',
  'Deoria',
  'Etah',
  'Kanshi Ram Nagar',
  'Etawah',
  'Firozabad',
  'Farrukhabad',
  'Fatehpur',
  'Faizabad',
  'Gautam Buddh Nagar',
  'Gonda',
  'Ghazipur',
  'Gorakhpur',
  'Ghaziabad',
  'Hardoi',
  'Mahamaya Nagar',
  'Jhansi',
  'Jalaun',
  'Jyotiba Phule Nagar',
  'Jaunpur district',
  'Ramabai Nagar',
  'Kannauj',
  'Kanpur',
  'Kaushambi',
  'Kushinagar',
  'Lalitpur',
  'Lakhimpur Kheri',
  'Lucknow',
  'Mau',
  'Meerut',
  'Maharajganj',
  'Mahoba',
  'Mirzapur',
  'Moradabad',
  'Mainpuri',
  'Mathura',
  'Muzaffarnagar',
  'Panchsheel',
  'Pilibhit',
  'Shamli',
  'Rampur',
  'Raebareli',
  'Saharanpur',
  'Sitapur',
  'Shahjahanpur',
  'Sant Kabir Nagar',
  'Siddharthnagar',
  'Sonbhadra',
  'Sant Ravidas Nagar',
  'Sultanpur',
  'Shravasti',
  'Unnao',
  'Varanasi',
  'Almora',
  'Vasai',
  'Vapi',
  'Bageshwar',
  'Chamoli',
  'Champawat',
  'Dehradun',
  'Haridwar',
  'Nainital',
  'Pauri Garhwal',
  'Pithoragarh',
  'Rudraprayag',
  'Tehri Garhwal',
  'Udham Singh Nagar',
  'Uttarkashi',
  'Birbhum',
  'Bankura',
  'Bardhaman',
  'Darjeeling',
  'Hooghly',
  'Howrah',
  'Jalpaiguri',
  'Cooch Behar',
  'Kolkata',
  'Maldah',
  'Medinipur',
  'Murshidabad',
  'Nadia',
  'Purulia',
  'Dinajpur',
];
