import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Row, Col, Button, Card, Tabs, Tag } from 'antd';
import { getDatabase, ref, child, get } from 'firebase/database';
import ProfileModal from './profile-details';
import Header from '../Header';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';

function sort_by_key(array, key) {
  if (array !== undefined) {
    return array.sort(function (a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return x < y ? 1 : x > y ? -1 : 0;
    });
  }
}

const { Meta } = Card;
const { TabPane } = Tabs;
const dbRef = ref(getDatabase());

const AppliedViaMonkAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [uniqueProfiles, setUniqueProfiles] = useState([]);
  const [profileId, setProfileId] = useState('');
  const [isModal, setModal] = useState(false);
  const [vacancies, setVacancies] = useState([]);
  const [job, setJob] = useState('');
  const [selectedTab, setSelectedTab] = useState('');

  const fetchProfiles = async (id) => {
    setJob(id);
    let list = [];
    await get(child(dbRef, `monk_applied_candidates/${id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((snap) => {
            list.push(snap.val());
          });
        } else {
          list = [];
        }
      })
      .catch((error) => {
        console.error(error);
      });

    setUniqueProfiles(sort_by_key(list, 'date_applied'));
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let list = [];
      await get(child(dbRef, `alljobs`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((snap) => {
              list.push(snap.val());
            });
            setVacancies(list);
            fetchProfiles(list[0].job_id);
          } else {
            // console.log('No data available');
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setLoading(false);
    }
    fetchData();
  }, []);

  const rerender = () => {
    setLoading(true);
    fetchProfiles(job);
  };

  const handleTabChange = (e) => {
    setSelectedTab(parseInt(e));

    setLoading(true);
    fetchProfiles(vacancies[e].job_id);
  };

  const newcolumns = [
    {
      title: 'Name',
      key: 'profile',
      align: 'center',
      width: '20%',
      render: (text) => (
        <div>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <img
                src={text?.photo}
                style={{
                  borderRadius: '10px',
                  height: '35px',
                  display: 'inline',
                  marginRight: '10px',
                }}
                referrerpolicy='no-referrer'
              />
            </div>
            <div style={{ textAlign: 'left' }}>
              <div>{text?.name}</div>
            </div>
          </Row>
        </div>
      ),
    },
    {
      title: 'Date Applied',
      key: 'date',
      align: 'center',
      width: '20%',
      dataIndex: 'date_applied',
      render: (dat) => (
        <div style={{ textAlign: 'center' }}>
          {new Date(dat).toLocaleDateString('en-IN')}
        </div>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      align: 'center',
      dataIndex: 'email',
      width: '20%',
      render: (current_company) => (
        <div style={{ textAlign: 'left' }}>{current_company}</div>
      ),
    },
    {
      title: 'Experience',
      key: 'experience',
      align: 'center',
      dataIndex: 'experience',
      width: '20%',
      render: (title) => (
        <div style={{ textAlign: 'center' }}>
          {title ? `${title} years` : '--'}
        </div>
      ),
    },
    {
      title: 'View Details',
      key: 'view',
      align: 'center',
      width: '20%',
      dataIndex: 'uid',
      render: (uid) => (
        <div>
          {uid ? (
            <Button
              onClick={() => {
                setProfileId(uid);
                setModal(true);
              }}>
              Details
            </Button>
          ) : (
            '--'
          )}
        </div>
      ),
    },
  ];

  const ChangeTabs = (key) => {
    // console.log(key);
  };

  return (
    <Wrapper>
      {localStorage['accessTokenid'] &&
      localStorage['provider'] === 'Recruiter' ? (
        <div>
          <SideMenu selectedKey={'3'} />
          <div style={{ marginLeft: '200px' }}>
            <Header />
            <div style={{ padding: '10px' }}>
              {isModal ? (
                <ProfileModal
                  visible={isModal}
                  id={profileId}
                  jobid={job}
                  rerender={rerender}
                  data={
                    uniqueProfiles.filter(
                      (item) => item.candidate_id === profileId
                    )[0]
                  }
                  closeModal={() => {
                    setModal(false);
                  }}
                />
              ) : null}
              <Row>
                <Col
                  span={24}
                  style={{
                    fontSize: 30,
                    fontWeight: '550',
                    paddingLeft: '5px',
                    marginBottom: '20px',
                  }}>
                  Monk Candidates
                </Col>
              </Row>

              <div className='tabs-style'>
                <Tabs
                  size='large'
                  defaultActiveKey='0'
                  onChange={handleTabChange}>
                  {vacancies?.length > 0
                    ? vacancies?.map((info, index) => {
                        return (
                          <TabPane
                            key={index}
                            tab={
                              <Card key={info.job_id}>
                                <div className='ant-main'>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      textTransform: 'capitalize',
                                    }}>
                                    <Link to={`/job/${info.job_id}`}>
                                      <Meta title={info.title} />
                                    </Link>
                                    <span
                                      style={{
                                        color: 'darkgray',
                                        fontSize: '13.5px',
                                      }}>
                                      Experience: {info.experience[0]}-
                                      {info.experience[1]}years
                                    </span>
                                  </div>
                                  <span>
                                    {info.company_name !== null
                                      ? info.company_name
                                      : ''}
                                  </span>
                                  <div style={{ margin: '8px 0px 5px 0px' }}>
                                    <Tag size='large' color='purple'>
                                      {info.experience[0]}-{info.experience[1]}{' '}
                                      years
                                    </Tag>
                                    <Tag
                                      color='blue'
                                      style={{ marginLeft: '2px' }}>
                                      {info.job_type}
                                    </Tag>
                                    <Tag
                                      color='cyan'
                                      style={{ marginLeft: '2px' }}>
                                      CTC - {info.salary}LPA
                                    </Tag>
                                  </div>
                                  <p>
                                    {info.location !== null &&
                                    info.location.length > 0
                                      ? info.location.join(', ')
                                      : ''}
                                  </p>
                                </div>
                              </Card>
                            }></TabPane>
                        );
                      })
                    : ''}
                </Tabs>
              </div>

              <div className='card-root'>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    marginBottom: '30px',
                  }}>
                  <Tabs onChange={ChangeTabs} defaultActiveKey={1}>
                    <TabPane tab='Candidates' key={1}>
                      <b>Total Candidates: {uniqueProfiles?.length}</b>
                      <p></p>
                      <Row gutter={[24, 24]}>
                        <Col span={24} style={{ height: 'auto' }}>
                          <Table
                            size='small'
                            style={{ marginRight: '15px' }}
                            loading={loading}
                            columns={newcolumns}
                            dataSource={uniqueProfiles}
                            //   pagination={{
                            //     current: monk_profiles?.pageNumber,
                            //     pageSize: monk_profiles?.pageSize,
                            //     total: monk_profiles?.total_length,
                            //     showTotal: handleShowTotal,
                            //   }}
                            //   onChange={handlePagination}
                            rowKey={'uid'}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default AppliedViaMonkAdmin;

const Wrapper = styled.div`
    background-color: #fff;
    width:99vw;
    overflow: hidden;
    .ant-tag{
        margin-top: 7px;
    }
    .ant-drawer {
        transform: none !important;
    }
    .ant-card-bordered {
      border: none;
    }
    .ant-card-body {
      padding: 0px;
    }
    .ant-main {
        padding: 10px;
    }
    .ant-main {
        width: 300px;
        height: auto;
    }
    .ant-tabs {
      width: 100% !important;
    }
    .tab2 .ant-tabs-tab-btn {
      font-size: 15px !important;
      font-weight: 500;
      margin-top: -5px;
    }
    .ant-tabs-tab-btn {
      font-size: 17px !important;
      font-weight: 500;
    }
    .ant-tabs-tab-active {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        transition: 0.15s all;
    }
    .ant-tabs-tab-active .ant-card-meta-title {
        color: #b32800 !important;
    }
    .ant-tabs-tab-active .ant-main {
        box-shadow: 0px -2px 30px -15px rgba(0, 0, 0, 0.3) inset;
    }
    .tabs-style .ant-tabs-ink-bar {
        background: #b32800 !important;
        height: 3px !important;
        border-radius: 16px !important;
    }
    p {
        margin-top: 7px;
        margin-bottom: 0px !important;
        color: gray;
        font-weight: 400;
    }
    .container {
      margin-left: 220px;
      height :100%;
      padding-bottom: 50px;
    }
  }
`;
