import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import PostJobForm from './Jobs/PostJobForm';
import EditJobForm from './Jobs/EditJobForm';
import CreateVacancy from './Company Admin/CreateVacancy';
import JobsApplied from './Candidate/JobsApplied';
import AppliedViaMonk from './Company Admin/AppliedViaMonk';
import AppliedViaMonkAdmin from './Monk Admin/AppliedViaMonkAdmin';
import CreateHackathon from './Monk Admin/CreateHackathon';
import SingleJob from './Jobs/SingleJob';
import Quiz from './Assessments/Quiz';
import QuizCandidates from './Monk Admin/QuizCandidates';
import AllCandidates from './Monk Admin/AllCandidates';

import RegisterHackathon from './Hackathon/RegisterHackathon';

const SubApp = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/admin' element={<QuizCandidates />} />
        <Route path='/all-candidates' element={<AllCandidates />} />
        <Route path='/postjob' element={<PostJobForm />} />
        <Route path='/editjob/:id' element={<EditJobForm />} />
        <Route path='/vacancies' element={<CreateVacancy />} />
        <Route path='/jobs-applied' element={<JobsApplied />} />
        <Route path='/monk-candidates' element={<AppliedViaMonk />} />
        <Route path='/register-hackathon' element={<RegisterHackathon />} />
        <Route
          path='/admin-monk-candidates'
          element={<AppliedViaMonkAdmin />}
        />

        <Route
          path="/create-hackathon"
          element={<CreateHackathon />}

        />
        <Route path='/job/:id' element={<SingleJob />} />
        <Route path='/assessment/:id' element={<Quiz />} />
      </Routes>
    </Router>
  );
};

export default SubApp;
