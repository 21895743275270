import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import styled from 'styled-components';

const AssessDetails = (props) => {
  const { data } = props;
  const [techP, setTechP] = useState();
  const [boolP, setBoolP] = useState();

  useEffect(() => {
    let techMax = 0,
      tech = 0,
      boolMax = 0,
      bool = 0;
    for (let i = 0; i < data.answers.length; i++) {
      if (data.answers[i].tag === 'Tech') {
        techMax = techMax + data.answers[i].maxScore;
        tech = tech + (data.answers[i].score >= 0 ? data.answers[i].score : 0);
      }

      if (data.answers[i].tag == 'Boolean') {
        boolMax = boolMax + data.answers[i].maxScore;
        bool = bool + (data.answers[i].score >= 0 ? data.answers[i].score : 0);
      }
    }

    setTechP(parseInt((tech * 100) / techMax));
    setBoolP(parseInt((bool * 100) / boolMax));
  }, []);

  return (
    <Wrapper>
      <div>
        <div className='top-quiz-title'>
          <h2>Assessment Overview</h2>
          {data?.totalScore !== '' ? (
            <h4>Total Score : {data?.totalScore}/25</h4>
          ) : (
            <h4>Scores Awaited</h4>
          )}
        </div>
        {data?.totalScore !== '' ? (
          <div className='bar-graph bar-graph-horizontal'>
            <div className='bar-one'>
              <span className='year'>Tech</span>
              <div
                className='bar'
                style={{ width: `${techP}%` }}
                data-percentage={techP + '%'}></div>
            </div>
            <div className='bar-two'>
              <span className='year'>Boolean</span>
              <div
                className='bar'
                style={{ width: `${boolP}%` }}
                data-percentage={boolP + '%'}></div>
            </div>
          </div>
        ) : null}
        {data ? (
          <Grid container>
            <Grid item xs={12}>
              <div align='center' className='qna'>
                <div className='show-all'>
                  <br />
                  <h3>Other Questions:</h3>
                  {data?.questions?.map((info, i) => {
                    if (info?.tag === 'JD')
                      return (
                        <div key={i} className='pb-6'>
                          <p className='show-ques'>Q. {info.question}</p>
                          {info.media !== '' &&
                          info.media !== undefined &&
                          info.media !== null ? (
                            <img src={info.media} alt={info.question} />
                          ) : null}
                          {info.info !== '' &&
                          info.info !== undefined &&
                          info.info !== null &&
                          info.info.length !== 0 ? (
                            <div>
                              <ol>
                                {info.info.map((info, k) => {
                                  return <li key={k}>{info}</li>;
                                })}
                              </ol>
                            </div>
                          ) : null}
                          {info.qualification !== '' &&
                          info.qualification !== undefined &&
                          info.qualification !== null &&
                          info.qualification.length !== 0 ? (
                            <div>
                              <p>Basic Qualifications</p>
                              <ul>
                                {info.qualification.map((info, k) => {
                                  return <li key={k}>{info}</li>;
                                })}
                              </ul>
                            </div>
                          ) : null}

                          {info.options !== '' &&
                          info.options !== undefined &&
                          info.options !== null &&
                          info.options.length !== 0 ? (
                            <div>
                              <strong>
                                <ul>
                                  {info.options.map((info, k) => {
                                    return <li key={k}>{info}</li>;
                                  })}
                                </ul>
                              </strong>
                            </div>
                          ) : null}
                          <div className='quiz-ans'>
                            <b>Ans.&nbsp;&nbsp;&nbsp;</b>
                            {data.answers[i]?.answer !== '' ? (
                              data.answers[i]?.answer
                            ) : (
                              <span className='font-serif text-red-600'>
                                NOT ANSWERED
                              </span>
                            )}
                          </div>

                          <div className='scoring'>
                            {data.totalScore !== '' ? (
                              <>
                                <p>
                                  <b>Score: {data.answers[i]?.score}</b>
                                </p>
                                <p>
                                  <b>
                                    Max Question Score:{' '}
                                    {data.questions[i]?.maxScore}
                                  </b>
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </Wrapper>
  );
};

AssessDetails.propTypes = {
  data: PropTypes.object,
};

export default AssessDetails;

const Wrapper = styled.div`
  background-color: #fff;
  .ant-drawer-header-title {
    justify-content: flex-end;
  }
  .ant-drawer-close {
    margin-right: 0px;
  }
  p {
    margin-bottom: 4px;
    white-space: nowrap;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
