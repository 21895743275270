import React from 'react';
import UserProvider from './Providers/UserProvider';
import './App.css';
import SubApp from './Components/SubApp';
import 'antd/dist/antd.css';

function App() {
  return (
    <UserProvider>
      <SubApp />
    </UserProvider>
  );
}

export default App;
