import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { Button, Checkbox, Select, Form, Input } from 'antd';
import { Slider, Col, Row, InputNumber, message } from 'antd';
import { ref, set, child, get, getDatabase } from 'firebase/database';
import { database } from '../../config/fire';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks3Icon from '@mui/icons-material/Looks3';
import { getStorage, uploadBytes, ref as sref } from 'firebase/storage';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { cities } from './Cities';
import Header from '../Header';
import SideMenu from '../Company Admin/SideMenu';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider';

const { Option } = Select;
const { TextArea } = Input;
const dbRef = ref(getDatabase());

const EditJobForm = () => {
  const params = useParams();
  const user = useContext(UserContext);
  const [image, setImage] = useState('');
  const [form] = Form.useForm();
  const [dis, setDis] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await get(
        child(dbRef, `jobs/${localStorage['accessTokenid']}/${params.id}`)
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            setData(snapshot.val());
            setCheck(snapshot.val().assessment);
            setSelected(snapshot.val().roles);
            form.setFieldsValue({
              about_job: snapshot.val().about_job,
              assessment: snapshot.val().assessment,
              // companyLogo: snapshot.val().companyLogo,
              company_bio: snapshot.val().company_bio,
              company_name: snapshot.val().company_name,
              company_size: snapshot.val().company_size,
              experience: snapshot.val().experience,
              hiringProcess: snapshot.val().hiringProcess,
              job_type: snapshot.val().job_type,
              location: snapshot.val().location,
              perks: snapshot.val().perks,
              recruiter: snapshot.val().recruiter,
              recruiter_email: snapshot.val().recruiter_email,
              recruiter_name: snapshot.val().recruiter_name,
              salary: snapshot.val().salary,
              title: snapshot.val().title,
              website: snapshot.val().website,
              linkedin: snapshot.val().linkedin,
              roles: snapshot.val().roles,
            });
          } else {
            // console.log('No data available');
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
    fetchData();
  }, []);

  const handleUpload = async (event) => {
    const file = event.target.files[0];

    const storage = getStorage();

    uploadBytes(
      sref(storage, localStorage['accessTokenid'] + '/' + file.name),
      file
    ).then((snapshot) => {
      setImage(
        'https://firebasestorage.googleapis.com/v0/b/hiring-monk.appspot.com/o/' +
        snapshot.metadata.fullPath.replace('/', '%2f') +
        '?alt=media'
      );
    });
  };

  const onFinish = async (values) => {
    values.salary = values.salary ? values.salary : '';
    values.perks = values.perks ? values.perks : '';
    values.hiringProcess = values.hiringProcess ? values.hiringProcess : '';
    values.companyLogo = image !== '' ? image : data.companyLogo;

    const dat = new Date();
    values.date_posted = data.date_posted;
    values.date_updated = dat.toISOString();

    if (dis) values.assessment = false;

    values.job_id = params.id;
    values.assessLink = values.assessment ? `/assessment/${params.id}` : '';

    await set(
      ref(database, 'jobs/' + localStorage['accessTokenid'] + '/' + params.id),
      values
    );

    await set(ref(database, 'alljobs/' + params.id), values);

    await set(
      ref(
        database,
        'recruiter_company_info/' + user.email.split('@')[1].split('.')[0]
      ),
      values
    );

    form.resetFields();
    message.success('Job Edited!!');
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const handleAssess = (val) => {
    if (val === 'Non-IT Recruiter') setDis(true);
    else setDis(false);
  };

  const handleCheckbox = (val) => {
    if (val.target.checked === true) setCheck(true);
    else setCheck(false);
  };

  const isMaxValues = selected.length === 2;

  return (
    <>
      <SideMenu selectedKey={'1'} />
      <div style={{ marginLeft: '200px' }}>
        <Header />

        <Grid
          container
          style={{ backgroundColor: 'black', padding: '10px 0px 30px 0px' }}>
          <Button onClick={() => navigate(-1)} style={{ marginLeft: '10px' }}>
            <ArrowLeftOutlined />
            Back
          </Button>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <span
              style={{ fontSize: '54px', color: '#b32800', fontWeight: '700' }}>
              Edit Your Job Opening
            </span>
            <br />
            <br />
            <span style={{ fontSize: '20px', color: 'white' }}>
              It is a curated job board for tech recruiters
            </span>
            <br />
          </Grid>
        </Grid>
        {localStorage['accessTokenid'] &&
          localStorage['provider'] === 'Recruiter' ? (
          <Grid
            container
            id='job-form'
            style={{
              display: 'flex',
              justifyContent: 'center',
              background: '#f7f7f7',
            }}>
            <Grid item xs={10}>
              {localStorage['accessTokenid'] ? (
                <Grid
                  container
                  style={{
                    background: '#fff',
                    padding: '10px 30px',
                  }}>
                  <Grid item xs={12}>
                    <h2>
                      <LooksOneIcon style={{ marginRight: '6px' }} /> Job
                      Information
                    </h2>
                    <Form
                      initialValues={{ remember: true }}
                      form={form}
                      onFinish={onFinish}
                      autoComplete='off'>
                      <Form.Item
                        label='Job Title'
                        name='title'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title',
                          },
                        ]}>
                        <Input />
                      </Form.Item>

                      <Row>
                        <Col span={11}>
                          <Form.Item
                            label='Role'
                            name='recruiter'
                            rules={[
                              {
                                required: true,
                                message: 'Please fill this field',
                              },
                            ]}>
                            <Select onChange={handleAssess}>
                              <Option value='IT Recruiter'>IT Recruiter</Option>
                              <Option value='Non-IT Recruiter'>
                                Non-IT Recruiter
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={2}></Col>

                        <Col span={11}>
                          <Form.Item
                            label='Job Type'
                            name='job_type'
                            rules={[
                              {
                                required: true,
                                message: 'Please fill this field',
                              },
                            ]}>
                            <Select>
                              <Option value='Contract'>Contract Staffing</Option>
                              <Option value='Permanent'>Permanent Hiring</Option>
                              <Option value='In-House TA'>In-House TA</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item
                        label='Location'
                        name='location'
                        rules={[
                          {
                            required: true,
                            message: 'Please fill this field',
                          },
                        ]}>
                        <Select mode='multiple'>
                          {cities.map((info) => {
                            return <Option key={info}>{info}</Option>;
                          })}
                        </Select>
                      </Form.Item>

                      <Row>
                        <Col span={11}>
                          <Form.Item label='Salary(lacs)' name='salary'>
                            <InputNumber />
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item label='Experience' name='experience'>
                            <Slider
                              range
                              defaultValue={[3, 7]}
                              max={30}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <hr />

                      <Grid item xs={12}>
                        <h2>
                          <LooksTwoIcon style={{ marginRight: '6px' }} />{' '}
                          Company Information
                        </h2>
                        <Form.Item
                          label='Company Name'
                          name='company_name'
                          rules={[
                            {
                              required: true,
                              message: 'Please fill this field',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                        <Row>
                          <Col span={11}>
                            <Form.Item
                              label='Company Size'
                              name='company_size'
                              rules={[
                                {
                                  required: true,
                                  message: 'Please fill this field',
                                },
                              ]}>
                              <Select>
                                <Option value='1-10 Employees'>
                                  1-10 Employees
                                </Option>
                                <Option value='10-100 Employees'>
                                  10-100 Employees
                                </Option>
                                <Option value='100-500 Employees'>
                                  100-500 Employees
                                </Option>
                                <Option value='500+ Employees'>
                                  500+ Employees
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={11}>
                            <Form.Item
                              label='Website'
                              name='website'
                              rules={[
                                {
                                  required: true,
                                  type: 'url',
                                  message: 'Please enter a valid url',
                                },
                              ]}>
                              <Input defaultValue='https://' />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item
                          label='Company Bio'
                          name='company_bio'
                          rules={[
                            {
                              required: true,
                              message: 'Please fill this field',
                            },
                          ]}>
                          <TextArea rows={3} />
                        </Form.Item>

                        <Row>
                          <Col span={11}>
                            <Form.Item
                              label='Company Logo'
                              name='companyLogo'
                              rules={[
                                {
                                  message: 'Please upload logo',
                                },
                              ]}>
                              <input
                                accept='image/*'
                                type='file'
                                onChange={handleUpload}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={11}>
                            <Form.Item
                              label='Linkedin URL'
                              name='linkedin'
                              rules={[
                                {
                                  required: true,
                                  type: 'url',
                                  message: 'Please enter a valid url',
                                },
                              ]}>
                              <Input defaultValue='https://' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Grid>
                      <hr />

                      <Grid item xs={12}>
                        <h2>
                          <Looks3Icon style={{ marginRight: '6px' }} /> Job
                          Description
                        </h2>

                        <Form.Item
                          label='About Job Role'
                          name='about_job'
                          rules={[
                            {
                              required: true,
                              message: 'Please fill this field',
                            },
                          ]}>
                          <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item label='Perks & Benefits' name='perks'>
                          <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                          label='Hiring Process/Stages'
                          name='hiringProcess'>
                          <TextArea rows={4} />
                        </Form.Item>
                      </Grid>
                      <hr />

                      <Grid item xs={12}>
                        <h2>
                          <Looks4Icon style={{ marginRight: '6px' }} /> Contact
                          Information
                        </h2>
                        <Row>
                          <Col span={11}>
                            <Form.Item
                              label='Recruiter Name'
                              name='recruiter_name'
                              rules={[
                                {
                                  required: true,
                                  message: 'Please fill this field',
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={11}>
                            <Form.Item
                              label='Recruiter Email'
                              name='recruiter_email'
                              rules={[
                                {
                                  required: true,
                                  type: 'email',
                                  message: 'Please enter a valid e-mail',
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Grid>

                      {dis ? null : (
                        <Form.Item
                          name='assessment'
                          valuePropName='checked'
                          onChange={handleCheckbox}
                          wrapperCol={{
                            offset: 3,
                            span: 19,
                          }}>
                          <Checkbox>
                            Do you need a assessment test to shortlist
                            candidates?
                          </Checkbox>
                        </Form.Item>
                      )}

                      {check ? (
                        <Form.Item
                          label='Choose Role(/s) you want them to comfortable with (Upto 2)'
                          name='roles'>
                          <Select
                            mode='multiple'
                            allowClear
                            disabled={false}
                            {...(isMaxValues && { open: false })}
                            value={selected}
                            onChange={(val) => setSelected(val)}
                            style={{
                              width: '100%',
                            }}
                            placeholder='Please select'>
                            <Option key='Devops' value='Devops'>
                              Devops Engineer
                            </Option>
                            {/* <Option key='Android' value='Android'>
                              Android Developer
                            </Option>
                            <Option key='iOS' value='iOS'>
                              iOS Developer
                            </Option>
                            <Option key='Java' value='Java'>
                              Java Developer
                            </Option> */}
                            {/* <Option key='.Net' value='.Net'>
                              .Net Developer
                            </Option> */}
                            <Option key='UI' value='UI'>
                              UI Developer
                            </Option>
                            <Option key='Data Engineer' value='Data Engineer'>
                              Data Engineer
                            </Option>
                            {/* <Option key='Data Scientist' value='Data Scientist'>
                              Data Scientist
                            </Option> */}
                            <Option key='Backend' value='Backend'>
                              Backend Developer
                            </Option>
                          </Select>
                        </Form.Item>
                      ) : null}

                      <Form.Item
                        wrapperCol={{
                          offset: 3,
                          span: 19,
                        }}>
                        <Button type='primary' htmlType='submit'>
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        ) : (
          <h2>Sign Up as Recruiter to Post A Job</h2>
        )}
      </div>
    </>
  );
};

export default EditJobForm;
