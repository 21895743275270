import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Tag, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import Header from '../Header';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { getDatabase, ref, child, get } from 'firebase/database';
import ApplyForm from '../Candidate/ApplyForm';
import { InfoCircleFilled } from '@ant-design/icons';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';

const dbRef = ref(getDatabase());

function ensureProtocol(url) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
}

const SingleJob = () => {
  const params = useParams();
  const [job, setJob] = useState();
  const [click, setClick] = useState(false);
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchdata() {
      await get(child(dbRef, `alljobs/${params.id}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setJob(snapshot.val());
          } else {
            // console.log('No data available');
          }
        })
        .catch((error) => {
          // console.error(error);
        });
      setLoading(false);
    }
    fetchdata();
  }, []);

  useEffect(() => {
    get(child(dbRef, `monk_applied_candidates/${params.id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          var key = Object.keys(snapshot.val());
          if (key.includes(localStorage['accessTokenid'])) setDis(true);
        } else {
          // console.log('No data available');
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  }, []);

  console.log(job);

  return (
    <>
      {click ? (
        <ApplyForm
          val={true}
          assess={job?.assessment}
          data={job}
          close={() => setClick(false)}
        />
      ) : null}
      <Header />
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          background: '#000',
        }}>
        <Grid
          item
          xs={11}
          md={8}
          style={{
            borderRadius: '16px',
            margin: '40px 0px',
            padding: '20px',
            background: '#fff',
          }}>
          <Spin spinning={loading}>
            {job ? (
              <div>
                <div
                  className='comp-logo'
                  style={{
                    display: 'flex',
                    textTransform: 'capitalize',
                  }}>
                  <img
                    src={job.companyLogo}
                    alt='image'
                    style={{ height: '50px', width: 'auto' }}
                  />
                  <div style={{ marginLeft: '20px', marginTop: '-5px' }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px'
                    }}>

                      <p style={{ margin: '0px', color: '#5f6c7b' }}>
                        {job.company_name}
                      </p>

                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        textDecoration: 'none'
                      }}>
                        <a href={ensureProtocol(job.linkedin)} style={{ color: 'inherit' }}>
                          <LinkedInIcon />
                        </a>
                        <a href={ensureProtocol(job.website)} style={{ color: 'inherit' }}>
                          <LinkIcon />
                        </a>
                      </div>
                    </div>

                    <h2 style={{ margin: '0px' }}>{job.title}</h2>
                  </div>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <Tag size='large' color='purple'>
                    {job.experience[0]}-{job.experience[1]} years
                  </Tag>
                  <Tag color='blue' style={{ marginLeft: '7px' }}>
                    {job.job_type}
                  </Tag>
                  <Tag color='cyan' style={{ marginLeft: '7px' }}>
                    {job.location.join(', ')}
                  </Tag>
                  <Tag color='magenta' style={{ marginLeft: '7px' }}>
                    CTC - {job.salary} LPA
                  </Tag>
                </div>

                <div className='flex-space'>
                  <Button
                    variant='contained'
                    disabled={dis}
                    className='btn-black'
                    onClick={() => setClick(true)}>
                    Apply Now
                  </Button>
                  <a
                    href={
                      job.website.includes('http')
                        ? job.website
                        : `https://${job.website}`
                    }
                    target='_blank'
                    className='flex-center'>
                    Visit Website
                    <CallMadeIcon />
                  </a>
                </div>
                <p className='flex-space-info'>
                  {dis ? (
                    <i>
                      <InfoCircleFilled style={{ color: '#1890ff' }} /> You have
                      already applied to this job
                    </i>
                  ) : null}
                </p>

                <div className='info-divs'>
                  <h2>About {job.company_name}</h2>
                  <p className='parse-text'>{job.company_bio}</p>
                </div>

                {/* <div className='info-divs'>
                  <h2>About Role</h2>
                  <p className='parse-text'>{job.about_job}</p>
                </div> */}

                {/* <Button
                  style={{ marginTop: '20px' }}
                  disabled={dis}
                  variant='contained'
                  className='btn-black'
                  onClick={() => setClick(true)}>
                  Apply Now
                </Button> */}
                <p className='flex-space-info2'>
                  {dis ? (
                    <i>
                      <InfoCircleFilled style={{ color: '#1890ff' }} /> You have
                      already applied to this job
                    </i>
                  ) : null}
                </p>
              </div>
            ) : (
              ''
            )}
          </Spin>
        </Grid>
      </Grid>
    </>
  );
};

export default SingleJob;
