import React, { useContext } from 'react';
import { useState, useEffect, useRef } from 'react';
import Countdown from 'react-countdown';
import Button from '@mui/material/Button';
import { getDatabase, ref, child, get, set, update } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Radio, Checkbox } from '@mui/material';
import { Stepper, StepLabel, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import Swal from 'sweetalert2';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { Spin, Menu } from 'antd';
import Community from '../Designs/community';
import Contact from './Contact';
import Header from '../Header';
import ProceedQuiz from './ProceedQuiz';
import { UserContext } from '../../Providers/UserProvider';
import { database } from '../../config/fire';
import { ClockCircleOutlined } from '@ant-design/icons';

const dbRef = ref(getDatabase());

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function getjd(job, jds) {
  let shuff_selected = [];

  if (
    job.roles[0] !== 'Backend' ||
    job.roles[0] !== 'UI' ||
    job.roles[0] !== 'Data Engineer' ||
    job.roles[0] !== 'Devops'
  ) {
    shuff_selected.push(...shuffle(jds).slice(0, 2));
  } else if (job.roles.length === 1 && job.roles[0] !== 'Other') {
    shuff_selected.push(jds.filter((item) => item.role[0] === job.roles[0]));
    shuff_selected.push(
      ...shuffle(jds.filter((item) => item.role[0] !== job.roles[0])).slice(
        0,
        1
      )
    );
  } else if (
    jds.filter(
      (item) => item.role[0] === job.roles[0] || item.role[0] === job.roles[1]
    ).length === 1
  ) {
    shuff_selected.push(
      jds.filter(
        (item) => item.role[0] === job.roles[0] || item.role[0] === job.roles[1]
      )
    );

    shuff_selected.push(
      ...shuffle(
        jds.filter(
          (item) =>
            item.role[0] !== job.roles[0] || item.role[0] !== job.roles[1]
        )
      ).slice(0, 1)
    );
  } else {
    shuff_selected.push(
      ...shuffle(
        jds.filter(
          (item) =>
            item.role[0] === job.roles[0] || item.role[0] === job.roles[1]
        )
      )
    );
  }

  return shuff_selected;
}

const Quiz = () => {
  const user = useContext(UserContext);
  const params = useParams();
  const [job, setJob] = useState();
  const [confirm, setConfirm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [intro, setIntro] = useState(false);
  const [userResponses, setUserResponses] = useState();
  const [flagged, setFlagged] = useState();
  const [attempt, setAttempt] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [check, setCheck] = useState(false);
  const [questions, setQuestions] = useState();
  const [maxScore, setMax] = useState();
  const [time, setTime] = useState();
  const [finish, setFinish] = useState(false);
  const [loading, setLoading] = useState(false);

  const globalJobs = JSON.parse(localStorage['jobs']);

  const startDate = useRef(Date.now());

  useEffect(() => {
    setLoading(true);
    get(
      child(
        dbRef,
        `quiz_taken_candidates/${params.id}/${localStorage['accessTokenid']}`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          setCheck(true);
        } else {
          // console.log('No data available');
        }
      })
      .catch((error) => {
        // console.error(error);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let jid;
      get(child(dbRef, `alljobs/${params.id}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setJob(snapshot.val());
            jid = snapshot.val();
          } else {
            // console.log('No data available');
          }
        })
        .catch((error) => {
          // console.error(error);
        });

      await get(child(dbRef, `questions`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();
            let selected = [];

            selected.push(...shuffle(data.tech_mcq).slice(0, 3));
            selected.push(...shuffle(data.tech_text).slice(0, 3));
            selected.push(...shuffle(data.boolean_text).slice(0, 1));
            selected.push(...shuffle(data.boolean_mcq).slice(0, 1));

            let new_selected = shuffle(selected);

            new_selected.push(...getjd(jid, data.jd));
            // console.log(selected);

            setQuestions(new_selected);
            setTime(data.time);
            setMax(data.maxScore);

            let list = [];
            let list2 = {};
            for (var i = 0; i < new_selected.length; i++) {
              let n = {
                sno: i,
                answer: '',
                score: -1,
                maxScore: new_selected[i].maxScore,
                tag: new_selected[i].tag,
                role: new_selected[i].role,
                correct_answer: new_selected[i].correct_answer,
              };
              list2[i] = false;
              list.push(n);
            }
            setAttempt(list2);
            setFlagged(list2);
            setUserResponses(list);
          } else {
            // console.log('No data available');
          }
        })
        .catch((error) => {
          // console.error(error);
        });

      setLoading(false);
    }
    fetchData();
  }, []);

  const handleAccept = () => {
    startDate.current = Date.now();
    setConfirm(true);
  };

  const timer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      Swal.fire({
        icon: 'info',
        title: 'Times Up! Your Answers are submitting...',
      });
      handleSubmit();
    } else {
      // Render a countdown
      return (
        <Grid item xs={12} className='clock-time'>
          <div>
            <ClockCircleOutlined style={{ marginRight: '8px' }} /> Time Left :{' '}
            <b style={{ marginLeft: '5px' }}>
              {minutes}:{seconds}
            </b>
          </div>
        </Grid>
      );
    }
  };

  const handleNext = () => {
    if (userResponses[activeStep].answer === '')
      setAttempt({
        ...attempt,
        [activeStep]: false,
      });
    else
      setAttempt({
        ...attempt,
        [activeStep]: true,
      });

    if (userResponses[activeStep].correct_answer[0] !== '') {
      if (
        userResponses[activeStep].answer ===
        userResponses[activeStep].correct_answer[0]
      )
        userResponses[activeStep].score = userResponses[activeStep].maxScore;
      else userResponses[activeStep].score = 0;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (userResponses[activeStep].answer === '')
      setAttempt({
        ...attempt,
        [activeStep]: false,
      });
    else
      setAttempt({
        ...attempt,
        [activeStep]: true,
      });

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleActive = (i) => {
    if (
      activeStep !== questions.length &&
      userResponses[activeStep].answer === ''
    )
      setAttempt({
        ...attempt,
        [activeStep]: false,
      });
    else if (activeStep !== questions.length)
      setAttempt({
        ...attempt,
        [activeStep]: true,
      });
    setActiveStep(i);
  };

  const handleFlag = (i) => {
    setFlagged({
      ...flagged,
      [activeStep]: !flagged[activeStep],
    });
  };

  // Task Response Related
  const handleText = (i) => (e) => {
    let resp = [...userResponses];
    resp[i]['sno'] = i;
    resp[i]['score'] = -1;
    resp[i]['answer'] = e.target.value;
    // console.log(resp);
    setUserResponses(resp);
  };

  const handleSubmit = async () => {
    setConfirm(true);
    setSubmitted(true);

    // send answers here
    let values = {};
    values.questions = questions;
    values.answers = userResponses;
    values.name = user.displayName ? user.displayName : '';
    values.email = user.email;
    values.photo = user.photoURL
      ? user.photoURL
      : 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png';
    values.uid = localStorage['accessTokenid'];

    const dat = new Date();
    values.date_applied = dat.toISOString();
    values.job_id = params.id;
    values.company_name = job.company_name;
    values.candidate_id = localStorage['accessTokenid'];
    values.totalScore = '';
    values.status = '';
    values.maxScore = maxScore;

    values.current_salary = '';
    values.expected_salary = '';
    values.notice = '';
    values.recruiter_status = '';

    if (globalJobs.length > 0) {
      globalJobs.map(async (job) => {
        values.job_id = job.job_id;
        values.company_name = job.company_name;

        await set(
          ref(
            database,
            'quiz_taken_candidates/' + job.job_id + '/' + localStorage['accessTokenid']
          ),
          values
        );
      });
    } else {
      await set(
        ref(
          database,
          'quiz_taken_candidates/' +
          params.id +
          '/' +
          localStorage['accessTokenid']
        ),
        values
      );
    }

    values.job_details = {
      experience: job.experience,
      location: job.location,
      salary: job.salary,
      title: job.title,
      logo: job.companyLogo,
      job_type: job.job_type,
    };


    // Update for all the globalJobs

    if (globalJobs.length > 0) {
      globalJobs.map(async (job) => {
        values.job_details = {
          experience: job.experience,
          location: job.location,
          salary: job.salary,
          title: job.title,
          logo: job.companyLogo,
          job_type: job.job_type,
        };
        await update(
          ref(
            database,
            'jobs-applied/' + localStorage['accessTokenid'] + '/' + job.job_id
          ),
          values
        );
      });
    } else {
      await update(
        ref(
          database,
          'jobs-applied/' + localStorage['accessTokenid'] + '/' + params.id
        ),
        values
      );
    }

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Allow-Origin', '*');

    var raw = JSON.stringify({
      to: 'ashfaq@recruitingmonk.com',
      candidate_name: values.name,
      recruiter_name: 'Ashfaq',
      job_title: job.title,
    });

    var raw2 = JSON.stringify({
      to: job.recruiter_email,
      candidate_name: values.name,
      recruiter_name: job.recruiter_name,
      job_title: job.title,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    var Options = {
      method: 'POST',
      headers: myHeaders,
      body: raw2,
    };

    await fetch(
      'https://monk-mailer.cyclic.app/admin-quiz-email',
      requestOptions
    );

    await fetch('https://monk-mailer.cyclic.app/quiz-email', Options);

    Swal.fire({
      icon: 'success',
      title: 'Awesome! Your Answers are successfully Submitted',
    }).then((result) => {
      if (result.isConfirmed) setFinish(true);
      else setFinish(true);
    });
  };

  return (
    <>
      {localStorage['accessTokenid'] &&
        localStorage['provider'] === 'Recruiter' ? (
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid container className='flex-it'>
            <Grid item xs={12} md={7} className='card-div proceed-qz'>
              <p>Hey Recruiter,</p>
              <p>Share this link with your candidate for tech assessment.</p>
              <p>
                This is a quick 60 mins assessment around basics of Tech,
                Sourcing & others.
              </p>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Spin spinning={loading}>
          {localStorage['accessTokenid'] && !check ? (
            <div>
              {confirm && user ? null : (
                <Grid container>
                  <Grid item xs={12}>
                    <Header />
                  </Grid>
                  {intro ? (
                    <Grid container className='flex-it'>
                      <Grid item xs={12} md={7} className='card-div'>
                        <form onSubmit={handleAccept}>
                          <p>
                            The questions can be answered in any order. You can
                            skip a question, flag it and come back to it later.
                          </p>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                            }}>
                            <p className='same-text-p'>
                              Answered Question{' '}
                              <CheckCircleIcon color='primary' />
                            </p>
                            <p
                              className='same-text-p'
                              style={{
                                background: '#F8E9D5',
                                padding: '5px 10px',
                                borderRadius: '8px',
                              }}>
                              Active Question
                            </p>
                            <p className='same-text-p'>
                              Flagged Question <FlagRoundedIcon color='error' />
                            </p>
                          </Grid>
                          <p>
                            <i>
                              Note: Once the assessment has started it cannot be
                              paused. Please ensure that you have sufficient
                              time and also a stable internet connection to
                              complete the assessment successfully.
                            </i>
                          </p>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Button
                              type='submit'
                              style={{ margin: '20px 0px' }}
                              className='back-button'
                              onClick={() => setIntro(false)}
                              variant='contained'>
                              Go Back
                            </Button>
                            <Button
                              type='submit'
                              style={{ margin: '20px 0px' }}
                              className='start-button2'
                              variant='contained'>
                              I’m ready, Let’s Start!
                            </Button>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container className='flex-it'>
                      <Grid item xs={12} md={7} className='card-div'>
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            setIntro(true);
                          }}>
                          <p>Hey {user ? user.displayName : null},</p>
                          <p>
                            Thanks for applying for a role of {job?.title} with{' '}
                            {job?.company_name}.
                          </p>
                          <p>
                            This is a 60min assessment to test you in sourcing,
                            tech and other related skills. This assessment has
                            multiple questions.
                          </p>
                          <p>
                            If you are ready to start the assessment please
                            click ‘Start Assessment’ button. Please use desktop
                            for better experience.
                          </p>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <Checkbox size='small' required />{' '}
                            <span className='text-log'>
                              I agree to the terms of the RecruitingMonk
                              assessment policy
                            </span>
                          </Grid>
                          <Button
                            type='submit'
                            style={{ margin: '20px 0px' }}
                            className='start-button'
                            variant='contained'>
                            Start Assessment
                          </Button>
                        </form>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}

              {confirm && !submitted && questions?.length > 0 ? (
                <>
                  <Header />
                  <Menu
                    className='qz-menu'
                    style={{
                      width: 200,
                      height: '100vh',
                      position: 'fixed',
                      paddingTop: 20,
                      background: '#fafaff',
                    }}
                    selectedKeys={[activeStep]}
                    mode='inline'>
                    {questions?.map((ques, index) => {
                      return (
                        <Menu.Item
                          key={index}
                          onClick={() => handleActive(index)}
                          style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            paddingLeft: '10px',
                          }}>
                          <Stepper
                            activeStep={activeStep}
                            orientation='vertical'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <StepLabel
                                className={
                                  activeStep === index
                                    ? 'active-question'
                                    : 'not-active'
                                }
                                style={{ marginLeft: '5px' }}>
                                Question {index + 1}
                              </StepLabel>
                              {attempt[index] ? (
                                <CheckCircleIcon
                                  style={{ marginLeft: '5px' }}
                                  color='primary'
                                />
                              ) : null}
                              {flagged[index] ? (
                                <FlagRoundedIcon
                                  style={{ marginLeft: '5px' }}
                                  color='error'
                                />
                              ) : null}
                            </div>
                          </Stepper>
                        </Menu.Item>
                      );
                    })}
                    <Menu.Item
                      key={questions.length}
                      onClick={() => handleActive(questions.length)}
                      style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        paddingLeft: '10px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <StepLabel
                          className={
                            activeStep === questions.length
                              ? 'active-question'
                              : 'not-active'
                          }
                          style={{ marginLeft: '5px' }}>
                          Finish{' '}
                          <img
                            width='24'
                            height='24'
                            src='/racing-flag.png'
                            alt='media'
                          />
                        </StepLabel>
                      </div>
                    </Menu.Item>
                  </Menu>
                  <Grid container className='quiz'>
                    <Grid
                      container
                      className='flex-it'
                      style={{ margin: '-20px 0px 0px 200px' }}>
                      {activeStep === questions.length ? (
                        <Grid item xs={12} md={7} className='card-div'>
                          <div className='question-number'>
                            <h1>Great Job!</h1>
                          </div>
                          <p>
                            You have reached the end of the assessment. Make
                            sure that you have answered all questions and
                            reviewed your answers before pressing ‘Submit’
                          </p>
                          <Grid item xs={12} className='question-number'>
                            <Button
                              type='submit'
                              style={{ margin: '20px 0px' }}
                              className='start-button2'
                              onClick={handleSubmit}
                              variant='contained'>
                              Submit
                            </Button>
                            <Button
                              type='submit'
                              style={{ margin: '20px 0px' }}
                              className='start-button'
                              onClick={() => setActiveStep(0)}
                              variant='contained'>
                              Review Answers
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={7} className='card-div'>
                          <div className='question-number'>
                            <h1>Question #{activeStep + 1}</h1>
                            <IconButton onClick={() => handleFlag(activeStep)}>
                              {flagged[activeStep] ? (
                                <FlagRoundedIcon color='error' />
                              ) : (
                                <OutlinedFlagRoundedIcon />
                              )}
                            </IconButton>
                          </div>
                          <p>{questions[activeStep].question}</p>
                          {questions[activeStep].media !== '' &&
                            questions[activeStep].media !== undefined &&
                            questions[activeStep].media !== null ? (
                            <img
                              src={questions[activeStep].media}
                              alt={questions[activeStep].question}
                            />
                          ) : null}
                          {questions[activeStep].info !== '' &&
                            questions[activeStep].info !== undefined &&
                            questions[activeStep].info !== null &&
                            questions[activeStep].info.length !== 0 ? (
                            <div>
                              <ol>
                                {questions[activeStep].info.map((info, k) => {
                                  return <li key={k}>{info}</li>;
                                })}
                              </ol>
                            </div>
                          ) : null}
                          {questions[activeStep].qualification !== '' &&
                            questions[activeStep].qualification !== undefined &&
                            questions[activeStep].qualification !== null &&
                            questions[activeStep].qualification.length !== 0 ? (
                            <div>
                              <ol>
                                {questions[activeStep].qualification.map(
                                  (info, k) => {
                                    return <li key={k}>{info}</li>;
                                  }
                                )}
                              </ol>
                            </div>
                          ) : null}

                          {questions[activeStep].options !== '' &&
                            questions[activeStep].options !== undefined &&
                            questions[activeStep].options !== null &&
                            questions[activeStep].options.length !== 0 ? (
                            <Grid item xs={12} style={{ padding: '10px' }}>
                              <FormControl component='fieldset'>
                                <RadioGroup
                                  name='answer'
                                  value={userResponses[activeStep]?.answer}
                                  onChange={handleText(activeStep)}>
                                  {questions[activeStep].options?.map(
                                    (option, j) => {
                                      return (
                                        <FormControlLabel
                                          key={j}
                                          value={option}
                                          control={<Radio />}
                                          label={option}
                                        />
                                      );
                                    }
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          ) : (
                            <Grid item xs={12} style={{ padding: '10px' }}>
                              <FormControl
                                component='fieldset'
                                className='answer-field'>
                                <TextField
                                  label='Enter your answer Here'
                                  variant='outlined'
                                  name='answer'
                                  value={userResponses[activeStep]?.answer}
                                  onChange={handleText(activeStep)}
                                  fullWidth
                                  multiline
                                  maxRows={5}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          <Grid item xs={12} className='question-number'>
                            {activeStep === 0 ? (
                              <p></p>
                            ) : (
                              <Button
                                type='submit'
                                style={{ margin: '20px 0px' }}
                                className='back-button'
                                onClick={handleBack}
                                variant='contained'>
                                Previous Question
                              </Button>
                            )}
                            <Button
                              type='submit'
                              style={{ margin: '20px 0px' }}
                              className='start-button'
                              onClick={handleNext}
                              variant='contained'>
                              Next Question
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Grid
                        container
                        style={{ alignItems: 'center', marginTop: '20px' }}>
                        <Grid item xs={12} md={8}>
                          <Contact />
                        </Grid>
                        <Grid item xs={12} md={4} className='clock'>
                          <Countdown
                            date={startDate.current + time * 1000}
                            renderer={timer}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </div>
          ) : !check ? (
            <ProceedQuiz />
          ) : (
            <ProceedQuiz />
          )}

          {finish ? (
            <Grid container>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid item xs={12}>
                <Community />
              </Grid>
            </Grid>
          ) : null}
        </Spin>
      )}
    </>
  );
};

export default Quiz;
