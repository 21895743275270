import styled from 'styled-components';

const Wrapper = styled.section`
  height: 100vh;
  position: fixed;
  background: #fafaff;
  z-index: 7;
  .menu-item {
    height: 30px;
    color: #6e7383;
  }
  .ant-drawer {
    transform: none !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e9eeff;
    color: #2754ff;
    font-size: 14px;
  }

  .ant-menu-item-active {
    color: #2754ff;
  }
  .ant-menu-item {
    font-size: 18px;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }
  .ant-menu-item {
    justify-content: center;
  }
`;

export default Wrapper;
