import React, { Component } from 'react';
import { Dialog, DialogContent, Button, Grid } from '@mui/material';
import { signInWithGoogle } from '../../config/fire';

const LoginPopup = (props) => {
  const { val, close } = props;

  return (
    <div align='center'>
      <Dialog
        open={val}
        onClose={close}
        aria-labelledby='login-popup'
        fullWidth>
        <DialogContent>
          <Grid container className='login'>
            <Grid item xs={12}>
              <p className='head'>Login To Proceed</p>
            </Grid>
            <Grid item xs={12}>
              <p></p>
            </Grid>
            <Grid item xs={12}>
              <button className='googleBtn' onClick={signInWithGoogle}>
                <img
                  src='https://img.pngio.com/google-logo-png-circle-google-icon-png-white-transparent-google-logo-white-png-654_665.png'
                  alt='logo'
                />
                Continue With Google
              </button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoginPopup;
