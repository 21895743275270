import React from 'react';
import { Grid } from '@mui/material';
import { signInWithGoogleReload } from '../../config/fire';

const NormalLogin = () => {
  return (
    <div align='center'>
      <Grid container className='login'>
        <Grid item xs={12}>
          <p className='head'>Login To Continue</p>
        </Grid>
        <Grid item xs={12}>
          <p></p>
        </Grid>
        <Grid item xs={12}>
          <button className='googleBtn' onClick={signInWithGoogleReload}>
            <img
              src='https://img.pngio.com/google-logo-png-circle-google-icon-png-white-transparent-google-logo-white-png-654_665.png'
              alt='logo'
            />
            Login With Google
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default NormalLogin;
