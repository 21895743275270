import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Button, Form, Input, message, Row, Checkbox, Radio } from 'antd';
import { ref, set, push, child } from 'firebase/database';
import { getStorage, uploadBytes, ref as sref } from 'firebase/storage';
import { database } from '../../config/fire';
import { useNavigate } from 'react-router-dom';


const dbRef = ref(database);


const CandidateForm = () => {
    const [form] = Form.useForm();
    const [check, setCheck] = useState(false);
    const [image, setImage] = useState('');

    const [radioValue, setRadioValue] = useState(30);

    const handleRadioChange = () => {
        setRadioValue(radioValue);
    };

    const navigate = useNavigate();


    const onFinish = async (values) => {
        values.fullName = values.full_name ? values.full_name : '';
        values.contact_email = values.contact_email ? values.contact_email : '';
        values.contact_phone = values.contact_phone ? values.contact_phone : '';
        values.linkedin_url = values.linkedin_url ? values.linkedin_url : '';
        values.current_location = values.current_location ? values.current_location : '';
        values.preferred_location = values.preferred_location ? values.preferred_location : '';

        values.min_exp = values.min_exp ? values.min_exp : '';
        values.max_exp = values.max_exp ? values.max_exp : '';

        values.min_ctc = values.min_ctc ? values.min_ctc : '';
        values.max_ctc = values.max_ctc ? values.max_ctc : '';

        values.notice_period = values.notice_period ? values.notice_period : '';

        values.recruiting_stories = values.recruiting_stories ? values.recruiting_stories : '';

        values.terms = values.terms ? values.terms : '';

        values.resume = image ? image : '';


        const newPostKey = push(
            child(ref(database), 'dev/hackathon/registrations/candidate/' + localStorage['accessTokenid'])
        ).key;
        values.job_id = newPostKey;
        values.assessLink = values.assessment ? `/assessment/${newPostKey}` : '';

        await set(
            ref(database, 'dev/hackathon/registrations/candidate/' + localStorage['accessTokenid'] + '/' + newPostKey),
            values
        );


        form.resetFields();
        message.success('Registration Done!!');
        setTimeout(() => {
            navigate('/vacancies');
        }, 1000);
    };

    const handleUpload = async (event) => {
        const file = event.target.files[0];

        const storage = getStorage();

        try {
            uploadBytes(
                sref(storage, localStorage['accessTokenid'] + '/' + file.name),
                file
            ).then((snapshot) => {
                setImage(
                    'https://firebasestorage.googleapis.com/v0/b/hiring-monk.appspot.com/o/' +
                    snapshot.metadata.fullPath.replace('/', '%2f') +
                    '?alt=media'
                );
                console.log('https://firebasestorage.googleapis.com/v0/b/hiring-monk.appspot.com/o/' +
                    snapshot.metadata.fullPath.replace('/', '%2f') +
                    '?alt=media');
                console.log('done');
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleCheckbox = (val) => {
        if (val.target.checked === true) setCheck(true);
        else setCheck(false);
    };

    return (
        <div>
            <div>
                {localStorage['accessTokenid'] &&
                    localStorage['provider'] === 'Recruiter' ? (
                    <Grid
                        container
                        id='job-form'
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#f7f7f7',
                        }}>
                        <Grid item xs={10}>

                            {localStorage['accessTokenid'] ? (
                                <Grid
                                    container
                                    style={{
                                        background: '#fff',
                                        padding: '10px 30px',
                                    }}>
                                    <Grid item xs={12}>
                                        <div
                                            style={{
                                                width: '100%',
                                                margin: '0 auto',
                                                marginTop: '20px',
                                                marginBottom: '20px',
                                            }}

                                        >
                                            <p
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    textTransform: 'uppercase'
                                                }}
                                            >
                                                RecruitingMonk Interview Drive - Candidate
                                                Information Form
                                            </p>
                                            <span className="text-[#1A1A1A]">
                                                We appreciate your interest in our Interview
                                                Drive. To get started, please provide the
                                                following information so we can assist you
                                                effectively.
                                            </span>
                                        </div>

                                        <hr style={{
                                            marginBottom: '40px',
                                        }} />

                                        <Form
                                            initialValues={{ remember: true, experience: [3, 7] }}
                                            form={form}
                                            layout='vertical'
                                            onFinish={onFinish}
                                            autoComplete='off'>

                                            <Grid item xs={12}>
                                                <Form.Item
                                                    label='Full Name'
                                                    name='full_name'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Apeksha Arun'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Email ID'
                                                    name='contact_email'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        type='email'
                                                        placeholder='name@gmail.com'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Contact Phone Number'
                                                    name='contact_phone'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                        {
                                                            pattern: /^\+\d{1,3} \d{1,14}$/,
                                                            message: 'Invalid phone number. It should be in the format +91 9663768811',
                                                        },
                                                    ]}>
                                                    <Input
                                                        type='tel'
                                                        placeholder='+91 9663768811'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='LinkedIn Profile URL'
                                                    name='linkedin_url'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                        {
                                                            pattern: /^www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
                                                            message: 'Invalid LinkedIn URL. It should be in the format www.linkedin.com/in/user',
                                                        }
                                                    ]}>
                                                    <Input
                                                        placeholder='www.linkedin.com/in/name/'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Current Location'
                                                    name='current_location'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Bangaluru'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Preferred Location'
                                                    name='preferred_location'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Bangaluru'
                                                    />
                                                </Form.Item>

                                                <Grid>

                                                    <p style={{
                                                        fontSize: '14px',
                                                        fontWeight: 500
                                                    }}>
                                                        <span style={{
                                                            color: '#ff4d4f',
                                                            marginRight: '4px'
                                                        }}>*</span>

                                                        Experience Range (in years)</p>
                                                    <Row style={{
                                                        gap: '10px',
                                                    }}>
                                                        <Form.Item
                                                            name='min_exp'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Required',
                                                                },
                                                            ]}
                                                        >
                                                            <Input type='number'
                                                                placeholder='min'
                                                                style={{

                                                                    width: '75px',
                                                                    height: '55px',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <p style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            {" - "}
                                                        </p>
                                                        <Form.Item
                                                            label=''
                                                            name='max_exp'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Required',
                                                                },
                                                            ]}>
                                                            <Input type='number'
                                                                placeholder='max'
                                                                style={{
                                                                    width: '75px',
                                                                    height: '55px',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Row>

                                                </Grid>

                                                <Grid>

                                                    <p style={{
                                                        fontSize: '14px',
                                                        fontWeight: 500
                                                    }}>
                                                        <span style={{
                                                            color: '#ff4d4f',
                                                            marginRight: '4px'
                                                        }}>*</span>
                                                        CTC (in LPA)</p>
                                                    <Row style={{
                                                        gap: '10px',
                                                    }}>
                                                        <Form.Item
                                                            name='min_ctc'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Required',
                                                                },
                                                            ]}
                                                        >
                                                            <Input type='number'
                                                                placeholder='min'
                                                                style={{

                                                                    width: '75px',
                                                                    height: '55px',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <p style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            {" - "}
                                                        </p>
                                                        <Form.Item
                                                            label=''
                                                            name='max_ctc'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Required',
                                                                },
                                                            ]}>
                                                            <Input type='number'
                                                                placeholder='max'
                                                                style={{
                                                                    width: '75px',
                                                                    height: '55px',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Row>

                                                </Grid>

                                                <Form.Item
                                                    label='Notice Period (in days)'
                                                    name='notice_period'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Radio.Group onChange={handleRadioChange} value={radioValue}>
                                                        <Radio value={30}>30</Radio>
                                                        <Radio value={60}>60</Radio>
                                                        <Radio value={90}>90</Radio>
                                                    </Radio.Group>

                                                </Form.Item>


                                                <Form.Item
                                                    label='Enter your top three recruiting stories'
                                                    name='recruiting_stories'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Bengaluru'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Resume'
                                                    name='resume'
                                                    rules={[
                                                        {
                                                            required: image === '' && true,
                                                            message: 'Please upload resume',
                                                        },
                                                    ]}>
                                                    <input
                                                        accept='.pdf,.doc,.docx'
                                                        type='file'
                                                        onChange={handleUpload}
                                                        style={{
                                                            border: '1px solid #D9D9D9',
                                                            borderRadius: '2px',
                                                            width: '100%',
                                                            padding: '4px'
                                                        }}
                                                    />
                                                    <span style={{
                                                        marginTop: '4px',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        lineHeight: '14.06px',
                                                        color: '#808080'
                                                    }}>
                                                        Max file size: 10Mb. File type - PDF, DOC, DOCX
                                                    </span>
                                                </Form.Item>


                                                <Form.Item
                                                    name='terms'
                                                    valuePropName='checked'
                                                >
                                                    <Checkbox onChange={handleCheckbox}>
                                                        By submitting this form, you agree to participate in the RecruitingMonk Interview Drive and acknowledge that the information provided is accurate. We will use this information for candidate selection and ranking purposes.
                                                    </Checkbox>
                                                </Form.Item>

                                            </Grid>

                                            <Form.Item>
                                                <Row justify='end' style={{
                                                    gap: '10px'
                                                }}>
                                                    <Button type='ghost' htmlType='reset'>
                                                        Back
                                                    </Button>
                                                    <Button type='primary' htmlType='submit'>
                                                        Submit
                                                    </Button>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </Grid>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <h2>Sign Up as Recruiter to Post A Job</h2>
                )}
            </div >
        </div >
    );
};

export default CandidateForm;
