import styles from './footer.module.scss';
import { Grid } from '@mui/material';

export default function Footer() {
  return (
    <div>
      <div className={styles.socialProfiles}>
        <a
          href='https://www.linkedin.com/company/recruitingmonk/'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/linkedin.png' />
        </a>
        <a
          href='https://www.facebook.com/recruitingmonk'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/facebook.png' />
        </a>
        <a
          href='https://twitter.com/recruitingmonk'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/twitter.png' />
        </a>
        <a
          href='https://www.youtube.com/channel/UCh12zgrebTnSYRerv9rxMVQ'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/youtube.png' />
        </a>
        <a
          href='https://www.instagram.com/recruitingmonk/'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/instagram.png' />
        </a>
      </div>
      <footer className={styles.footer}>
        <div className='container'>
          <div className={styles.logoss}>
            <img src='/images/logo.png' width={200} height={60} />
          </div>
          <div className={styles.description}>
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12} md={8}>
                India’s largest community-driven Recruiting Ecosystem that
                combines years of industry experience with tech expertise to
                impact recruiters and recruiting.
              </Grid>
            </Grid>
          </div>
          <hr className={styles.hr}></hr>
          <div className={styles.copyrightText}>
            © 2022 RecruitingMonk all rights reserved
          </div>
        </div>
      </footer>
    </div>
  );
}
