import styled from 'styled-components';
import React from 'react';
import { Grid } from '@mui/material';
import { Button, Form, Input, DatePicker, Row, Col } from 'antd';
import { message } from 'antd';
import { ref, set, push, child } from 'firebase/database';
import { database } from '../../config/fire';
import Looks3Icon from '@mui/icons-material/Looks3';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Header from '../Header';
import SideMenu from './SideMenu';
import { useNavigate } from 'react-router-dom';


const { TextArea } = Input;

const CreateHackathon = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();


    const onFinish = async (values) => {
        values.start_date = values.start_date.toISOString();
        values.end_date = values.end_date.toISOString();

        const newPostKey = push(
            child(ref(database), 'dev/hackathon/' + localStorage['accessTokenid'])
        ).key;
        values.job_id = newPostKey;


        await set(
            ref(database, 'dev/hackathon/' + localStorage['accessTokenid'] + '/' + newPostKey),
            values
        );

        await set(ref(database, 'dev/allhackathons/' + newPostKey), values);

        form.resetFields();
        message.success('Hackathon Posted!!');
        setTimeout(() => {
            navigate('/vacancies');
        }, 1000);
    };


    return (
        <Wrapper>
            {localStorage['accessTokenid'] &&
                localStorage['provider'] === 'Recruiter' ? (
                <div>
                    <SideMenu selectedKey={'4'} />
                    <div style={{ marginLeft: '200px' }}>
                        <Header />
                        <Grid
                            container
                            style={{ backgroundColor: 'black', padding: '10px 0px 30px 0px' }}>
                            <Button onClick={() => navigate(-1)} style={{ marginLeft: '10px' }}>
                                <ArrowLeftOutlined />
                                Back
                            </Button>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <span
                                    style={{ fontSize: '54px', color: '#b32800', fontWeight: '700' }}>
                                    Post Hackathon Details
                                </span>
                                <br />
                                <br />
                                <span style={{ fontSize: '20px', color: 'white' }}>
                                    Fill in the details of the hackathon you want to post
                                </span>
                                <br />
                            </Grid>
                        </Grid>
                        {localStorage['accessTokenid'] &&
                            localStorage['provider'] === 'Recruiter' ? (
                            <Grid
                                container
                                id='job-form'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    background: '#f7f7f7',
                                }}>
                                <Grid item xs={10}>
                                    {localStorage['accessTokenid'] ? (
                                        <Grid
                                            container
                                            style={{
                                                background: '#fff',
                                                padding: '10px 30px',
                                            }}>
                                            <Grid item xs={12}>
                                                <h2>
                                                    <Looks3Icon style={{ marginRight: '6px' }} /> Hackathon
                                                    Description
                                                </h2>
                                                <Form
                                                    initialValues={{ remember: true, experience: [3, 7] }}
                                                    form={form}
                                                    onFinish={onFinish}
                                                    autoComplete='off'>
                                                    <Form.Item
                                                        label='Hackathon Name'
                                                        name='title'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter title',
                                                            },
                                                        ]}>
                                                        <Input />
                                                    </Form.Item>

                                                    <Grid item xs={12}>
                                                        <Form.Item
                                                            label='About Hackathon'
                                                            name='about_hackathon'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please fill this field',
                                                                },
                                                            ]}>
                                                            <TextArea rows={4} />
                                                        </Form.Item>
                                                    </Grid>

                                                    <Row>
                                                        <Col span={11}>

                                                            <Form.Item
                                                                label="Start Date"
                                                                name='start_date'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter date',
                                                                    },
                                                                ]}

                                                            >
                                                                <DatePicker />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={2}></Col>

                                                        <Col span={11}>
                                                            <Form.Item
                                                                label="End Date"
                                                                name='end_date'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter date',
                                                                    },
                                                                ]}

                                                            >
                                                                <DatePicker />
                                                            </Form.Item>
                                                        </Col>

                                                    </Row>

                                                    <Form.Item
                                                        wrapperCol={{
                                                            offset: 3,
                                                            span: 19,
                                                        }}>
                                                        <Button type='primary' htmlType='submit'>
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        ) : (
                            <h2>Sign Up as Recruiter to Post A Job</h2>
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </Wrapper>
    );
};

export default CreateHackathon;

const Wrapper = styled.div`
    background-color: #fff;
    width:99vw;
    overflow: hidden;
    .ant-tag{
        margin-top: 7px;
    }
    .ant-drawer {
        transform: none !important;
    }
    .ant-card-bordered {
      border: none;
    }
    .ant-card-body {
      padding: 0px;
    }
    .ant-main {
        padding: 10px;
    }
    .ant-main {
        width: 300px;
        height: auto;
    }
    .ant-tabs {
      width: 100% !important;
    }
    .tab2 .ant-tabs-tab-btn {
      font-size: 15px !important;
      font-weight: 500;
      margin-top: -5px;
    }
    .ant-tabs-tab-btn {
      font-size: 17px !important;
      font-weight: 500;
    }
    .ant-tabs-tab-active {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        transition: 0.15s all;
    }
    .ant-tabs-tab-active .ant-card-meta-title {
        color: #b32800 !important;
    }
    .ant-tabs-tab-active .ant-main {
        box-shadow: 0px -2px 30px -15px rgba(0, 0, 0, 0.3) inset;
    }
    .tabs-style .ant-tabs-ink-bar {
        background: #b32800 !important;
        height: 3px !important;
        border-radius: 16px !important;
    }
    p {
        margin-top: 7px;
        margin-bottom: 0px !important;
        color: gray;
        font-weight: 400;
    }
    .container {
      margin-left: 220px;
      height :100%;
      padding-bottom: 50px;
    }
  }
`;
