import React from "react";
import styles from './styles.module.scss';

import vectorImage1 from "../../assets/vectorimages/1.png";
import vectorImage2 from "../../assets/vectorimages/2.png";
import vectorImage3 from "../../assets/vectorimages/3.png";
import vectorImage4 from "../../assets/vectorimages/4.png";
import vectorImage5 from "../../assets/vectorimages/5.png";
import vectorImage6 from "../../assets/vectorimages/6.png";

const vectorImagesList = [
    vectorImage1,
    vectorImage2,
    vectorImage3,
    vectorImage4,
    vectorImage5,
    vectorImage6,
];

const OptionChoosingPage = ({
    setOptionSelected,
}) => {
    return <main className={styles.main}>
        <div className={styles.relative}>
            <div className={styles['flex-col']}>
                {vectorImagesList.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`imagex-${index}`}
                        className={styles.img}
                    />
                ))}
            </div>

            <div className={styles['w-full']}>
                <div className={styles['flex-col']}>
                    <p>
                        First things first, what are you looking
                        for?
                    </p>

                    <div className={styles.flex}>
                        <ButtonComponnent
                            text="I am looking to hire"
                            setOptionSelected={setOptionSelected}
                            userType="employer"
                        />
                        <ButtonComponnent
                            text="I am looking to interview"
                            setOptionSelected={setOptionSelected}
                            userType="candidate"
                        />
                    </div>

                    <button className={styles.back}>
                        Back
                    </button>
                </div>
            </div>
        </div>
    </main>;
};

const ButtonComponnent = ({ text, setOptionSelected, userType }) => {
    return (
        <button className={styles.roundedDiv}
            onClick={() => setOptionSelected(userType)}
        >
            {text}
        </button>
    );
};

export default OptionChoosingPage;