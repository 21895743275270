import React from 'react';
import { Grid } from '@mui/material';
import { Button, Form, Input, DatePicker, message, Row, Col } from 'antd';
import { ref, set, push, child } from 'firebase/database';
import { database } from '../../config/fire';
import { useNavigate } from 'react-router-dom';


const EmployerForm = () => {
    const [form] = Form.useForm();

    const navigate = useNavigate();


    const onFinish = async (values) => {

        values.company_name = values.company_name ? values.company_name : '';
        values.contact_person = values.contact_person ? values.contact_person : '';
        values.contact_email = values.contact_email ? values.contact_email : '';
        values.contact_phone = values.contact_phone ? values.contact_phone : '';
        values.requirements = values.requirements ? values.requirements : '';
        values.interview_date = values.interview_date.toISOString();
        values.additionalInfo = values.additionalInfo ? values.additionalInfo : '';

        const newPostKey = push(
            child(ref(database), 'dev/hackathon/registrations/employer/' + localStorage['accessTokenid'])
        ).key;
        values.hackathonId = newPostKey;
        values.assessLink = values.assessment ? `/assessment/${newPostKey}` : '';

        await set(
            ref(database, 'dev/hackathon/registrations/employer/' + localStorage['accessTokenid'] + '/' + newPostKey),
            values
        );


        form.resetFields();
        message.success('Hackathon Posted!!');
        setTimeout(() => {
            navigate('/vacancies');
        }, 1000);
    };

    return (
        <div>
            <div>
                {localStorage['accessTokenid'] &&
                    localStorage['provider'] === 'Recruiter' ? (
                    <Grid
                        container
                        id='job-form'
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#f7f7f7',
                        }}>
                        <Grid item xs={10}>

                            {localStorage['accessTokenid'] ? (
                                <Grid
                                    container
                                    style={{
                                        background: '#fff',
                                        padding: '10px 30px',
                                    }}>
                                    <Grid item xs={12}>
                                        <div
                                            style={{
                                                width: '100%',
                                                margin: '0 auto',
                                                marginTop: '20px',
                                                marginBottom: '20px',
                                            }}

                                        >
                                            <p
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    textTransform: 'uppercase'
                                                }}
                                            >
                                                RecruitingMonk Interview Drive - Company
                                                Information Form
                                            </p>
                                            <span className="text-[#1A1A1A]">
                                                We appreciate your interest in our Interview
                                                Drive. To get started, please provide the
                                                following information so we can assist you
                                                effectively.
                                            </span>
                                        </div>

                                        <hr style={{
                                            marginBottom: '40px',
                                        }} />

                                        <Form
                                            initialValues={{ remember: true, experience: [3, 7] }}
                                            form={form}
                                            layout='vertical'
                                            onFinish={onFinish}
                                            autoComplete='off'>

                                            <Grid item xs={12}>
                                                <Form.Item
                                                    label='Company Name'
                                                    name='company_name'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Google'

                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Contact Person'
                                                    name='contact_person'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Agam Shah'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Contact Email ID'
                                                    name='contact_email'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        type='email'
                                                        placeholder='name@gmail.com'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Contact Phone Number'
                                                    name='contact_phone'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                        {
                                                            pattern: /^\+\d{1,3} \d{1,14}$/,
                                                            message: 'Invalid phone number. It should be in the format +91 9663768811',
                                                        },
                                                    ]}>
                                                    <Input
                                                        type='tel'
                                                        placeholder='+91 9663768811'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Tech Recruiter Requirements'
                                                    name='requirements'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <Input
                                                        type='tel'
                                                        placeholder="Number of Tech Recruiters you're looking to hire"
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Preferred Interview Drive Date'
                                                    name='interview_date'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please fill this field',
                                                        },
                                                    ]}>
                                                    <DatePicker />
                                                </Form.Item>


                                                <Form.Item
                                                    label='Additional Information or Special Requirements'
                                                    name='additional_information'
                                                >
                                                    <Input
                                                        placeholder='Requirements'
                                                    />
                                                </Form.Item>
                                            </Grid>

                                            <Form.Item>
                                                <Row justify='end' style={{
                                                    gap: '10px'
                                                }}>
                                                    <Button type='ghost' htmlType='reset'>
                                                        Back
                                                    </Button>
                                                    <Button type='primary' htmlType='submit'>
                                                        Submit
                                                    </Button>


                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </Grid>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <h2>Sign Up as Recruiter to Post A Job</h2>
                )}
            </div>
        </div>
    );
};

export default EmployerForm;
