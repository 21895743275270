import React, { useState, useEffect, useContext } from 'react';
import { Button, Radio, Form } from 'antd';
import { Modal, Col, Row, InputNumber, message, Spin } from 'antd';
import PropTypes from 'prop-types';
import { ref, set, update } from 'firebase/database';
import { database } from '../../config/fire';
import { useParams, useNavigate } from 'react-router-dom';
import { getStorage, uploadBytes, ref as sref } from 'firebase/storage';
import { getDatabase, ref as dref, child, get } from 'firebase/database';
import { UserContext } from '../../Providers/UserProvider';
import NormalLogin from '../Login/NormalLogin';

const dbRef = dref(getDatabase());

const ApplyForm = (props) => {
  const { val, close, assess, data } = props;
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const [resume, setResume] = useState('');
  const [prevresume, setPrevResume] = useState('');
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(val);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVisible(val);
  }, [props]);

  useEffect(() => {
    get(child(dbRef, `candidates_info/${localStorage['accessTokenid']}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setPrevResume(snapshot.val().resume);
          form.setFieldsValue({
            current_salary: snapshot.val().current_salary,
            expected_salary: snapshot.val().expected_salary,
            experience: snapshot.val().experience,
            experience_type: snapshot.val().experience_type,
            notice: snapshot.val().notice,
          });
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];

    const storage = getStorage();

    await uploadBytes(
      sref(storage, localStorage['accessTokenid'] + '/' + file.name),
      file
    ).then((snapshot) => {
      setResume(
        'https://firebasestorage.googleapis.com/v0/b/hiring-monk.appspot.com/o/' +
          encodeURIComponent(snapshot.metadata.fullPath) +
          '?alt=media'
      );
    });

    setLoading(false);
  };

  const onFinish = async (values) => {
    setConfirmLoading(true);
    values.resume = resume !== '' ? resume : prevresume;
    values.name = user.displayName ? user.displayName : '';
    values.email = user.email;
    values.photo = user.photoURL
      ? user.photoURL
      : 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png';
    values.uid = localStorage['accessTokenid'];

    await set(
      ref(database, 'candidates_info/' + localStorage['accessTokenid']),
      values
    );

    const dat = new Date();
    values.date_applied = dat.toISOString();
    values.job_id = params.id;
    values.candidate_id = localStorage['accessTokenid'];
    values.recruiter_status = '';

    await set(
      ref(
        database,
        'monk_applied_candidates/' +
          params.id +
          '/' +
          localStorage['accessTokenid']
      ),
      values
    );

    values.job_details = {
      experience: data.experience,
      location: data.location,
      salary: data.salary,
      title: data.title,
      logo: data.companyLogo,
      job_type: data.job_type,
    };

    await update(
      ref(
        database,
        'jobs-applied/' + localStorage['accessTokenid'] + '/' + params.id
      ),
      values
    );

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Allow-Origin', '*');

    var raw = JSON.stringify({
      to: 'ashfaq@recruitingmonk.com',
      candidate_name: values.name,
      recruiter_name: 'Ashfaq',
      job_title: data.title,
    });

    var raw2 = JSON.stringify({
      to: data.recruiter_email,
      candidate_name: values.name,
      recruiter_name: data.recruiter_name,
      job_title: data.title,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    var Options = {
      method: 'POST',
      headers: myHeaders,
      body: raw2,
    };

    await fetch(
      'https://monk-mailer.cyclic.app/admin-form-email',
      requestOptions
    );

    await fetch('https://monk-mailer.cyclic.app/form-email', Options);

    form.resetFields();

    setTimeout(() => {
      setConfirmLoading(false);
      close();
      message.success('Applied!!');
      setTimeout(() => {
        if (assess) navigate(`/assessment/${params.id}`);
        else window.location.reload();
      }, 1000);
    }, 700);
  };

  return (
    <>
      <Modal
        title='Apply for Job'
        visible={visible}
        footer={null}
        onCancel={close}>
        {localStorage['accessTokenid'] &&
        localStorage['provider'] !== 'Recruiter' ? (
          <Spin spinning={loading}>
            <Form
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={onFinish}
              autoComplete='off'>
              <Form.Item
                label='Resume'
                name='resume'
                rules={[
                  {
                    required: prevresume === '' && resume === '' ? true : false,
                    message: 'This field is required',
                  },
                ]}>
                <input accept='.pdf' type='file' onChange={handleUpload} />
                {prevresume !== '' && resume === '' ? (
                  <a href={prevresume} target='_blank'>
                    Your Previous Upload
                  </a>
                ) : null}
              </Form.Item>
              <Form.Item
                label='Experience (in years)'
                name='experience'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}>
                <InputNumber type='number' />
              </Form.Item>
              <Form.Item
                label='Current Salary (in LPA)'
                name='current_salary'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}>
                <InputNumber type='number' />
              </Form.Item>
              <Form.Item
                label='Expected Salary (in LPA)'
                name='expected_salary'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}>
                <InputNumber type='number' />
              </Form.Item>
              <Form.Item
                label='Notice Period (in days)'
                name='notice'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}>
                <InputNumber type='number' />
              </Form.Item>
              <Form.Item
                label='Experience in Domestic or US Staffing?'
                name='experience_type'
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}>
                <Radio.Group buttonStyle='solid'>
                  <Radio.Button value='Domestic'>Domestic</Radio.Button>
                  <Radio.Button value='US'>US</Radio.Button>
                  <Radio.Button value='Both'>Both</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Row style={{ marginTop: '10px', marginBottom: '-15px' }}>
                <Col span={12}></Col>
                <Col span={6}>
                  <Form.Item>
                    <Button
                      style={{ float: 'right', marginRight: '5px' }}
                      onClick={close}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    <Button
                      style={{ float: 'right' }}
                      type='primary'
                      htmlType='submit'
                      loading={confirmLoading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        ) : (
          <NormalLogin />
        )}
      </Modal>
    </>
  );
};

ApplyForm.propTypes = {
  val: PropTypes.any,
  close: PropTypes.func,
};

export default ApplyForm;
