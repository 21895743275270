import React from "react";
import OptionChoosingPage from './OptionChoosingPage';

import EmployerForm from './EmployerForm';
import CandidateForm from './CandidateForm';

const RegisterHackathonPage = () => {
    const [optionSelected, setOptionSelected] = React.useState(null); // '' or 'employer' or 'candidate'

    if (!optionSelected) return <OptionChoosingPage
        setOptionSelected={setOptionSelected} />;

    return (
        <div>
            {optionSelected === 'employer' && <EmployerForm />}
            {optionSelected === 'candidate' && <CandidateForm />}
        </div>
    );
};

export default RegisterHackathonPage;

