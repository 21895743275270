import styles from './popular-questions.module.scss';
import { Grid } from '@mui/material';

const PopularQuestions = () => {
  return (
    <>
      <Grid className={styles.popularQuestions}>
        <h1 className={styles.title}>Popular questions</h1>
        <Grid className={styles.questionsContainer}>
          <Grid
            container
            spacing={3}
            style={{ marginBottom: '20px', justifyContent: 'center' }}>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/what-are-various-online-boolean-or-x-ray-string-generators-0llg3rx72kr'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>
                      What are various online Boolean or X-Ray String
                      Generators?
                    </p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 3
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 7
                        </div>
                        <div className={styles.hashtag}>#boolean</div>
                        <div className={styles.hashtag}>#sourcing</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/how-to-source-profiles-on-angellist--angelco-dweysecxx5s'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>How to source profiles on AngelList / angel.co?</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 4
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 2
                        </div>
                        <div className={styles.hashtag}>#xray</div>
                        <div className={styles.hashtag}>#sourcing</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/how-to-identify-fake-candidates-while-screening-cc8ltxlsaww'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>How to identify fake candidates while screening?</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 2
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 5
                        </div>
                        <div className={styles.hashtag}>#screen</div>
                        <div className={styles.hashtag}>#sourcing</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/is-there-a-strategy-to-reduce-offer-drop-outs-ukr3hp84bo'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>Is there a strategy to reduce offer drop outs?</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 2
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 5
                        </div>
                        <div className={styles.hashtag}>#recruiting</div>
                        <div className={styles.hashtag}>#dropoffs</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/four-types-of-recruiters-in-2022-and-why-you-would-be-want-to-be-a-linchpin-recruiter'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>
                      Four Types of Recruiters in 2022 and why you would be want
                      to be a "Linchpin Recruiter"?
                    </p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 4
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 0
                        </div>
                        <div className={styles.hashtag}>#recruiting</div>
                        <div className={styles.hashtag}>#career</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/podcasts-every-recruiter-must-listen-to'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>Podcasts every recruiter must listen to!</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 2
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 0
                        </div>
                        <div className={styles.hashtag}>#hiring</div>
                        <div className={styles.hashtag}>#podcast</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/a-recruiters-best-friend-ai-'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>A recruiters best friend - AI</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 1
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 0
                        </div>
                        <div className={styles.hashtag}>#recruiting</div>
                        <div className={styles.hashtag}>#technology</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ marginBottom: '20px', justifyContent: 'center' }}>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/agile-sourcing-by-thoughtworks-id7drk9j2e'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>Agile sourcing by ThoughtWorks</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 3
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 7
                        </div>
                        <div className={styles.hashtag}>#agile</div>
                        <div className={styles.hashtag}>#sourcing</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/what-are-the-top-free-web-scrapers-that-works-on-linkedin-yvvr2rj2lo'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>
                      What are the top free web scrapers that works on LinkedIn?
                    </p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 0
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 5
                        </div>
                        <div className={styles.hashtag}>#hiring</div>
                        <div className={styles.hashtag}>#sourcing</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.question}>
                <a
                  href='https://community.recruitingmonk.com/p/datascience-demystified-for-recruiters-oqbesydyo38'
                  role='button'
                  rel='noreferrer'
                  target='_blank'>
                  <div className={styles.content}>
                    <p>DataScience Demystified for Recruiters</p>
                    <div className={styles.actions}>
                      <div className={styles.partOne}>
                        <div className={styles.actionIcon}>
                          <img src='/like.png' alt='Like' /> 3
                        </div>
                        <div className={styles.actionIcon}>
                          <img src='/comment.png' alt='Comment' /> 5
                        </div>
                        <div className={styles.hashtag}>#datascience</div>
                        <div className={styles.hashtag}>#event</div>
                      </div>
                      <div className={styles.partTwo}>
                        <div>
                          <img src='/sign-out.png' alt='Checkout' />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PopularQuestions;
