import React from 'react';
import Header from '../Header';
import { UserContext } from '../../Providers/UserProvider';
import { useContext, useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import { getDatabase, ref, child, get, remove } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, message, Card, Empty, Popconfirm } from 'antd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Col, Row } from 'antd';
import IconButton from '@mui/material/IconButton';
import { PlusCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const dbRef = ref(getDatabase());

const CreateVacancy = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let list = [];
      await get(child(dbRef, `jobs/${localStorage['accessTokenid']}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((snap) => {
              list.push(snap.val());
            });
            setVacancies(list);
          } else {
            console.log('No data available');
            setVacancies([]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    let list = [];
    await get(child(dbRef, `jobs/${localStorage['accessTokenid']}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((snap) => {
            list.push(snap.val());
          });
          setVacancies(list);
        } else {
          console.log('No data available');
          setVacancies([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChange = (key) => {
    console.log(key);
  };

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    message.success('Copied!');
  };

  const handleDelete = async (id) => {
    await remove(child(dbRef, `alljobs/${id}`))
      .then((snap) => {
        console.log(snap);
      })
      .catch((error) => {
        console.error(error);
      });

    await remove(child(dbRef, `jobs/${localStorage['accessTokenid']}/${id}`))
      .then((snap) => {
        message.success('Job Post Deleted');
        fetchData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Wrapper>
      {localStorage['accessTokenid'] &&
        localStorage['provider'] === 'Recruiter' ? (
        <div>
          <SideMenu selectedKey={'1'} />
          <div style={{ marginLeft: '200px' }}>
            <Header />
          </div>
          <div className='container'>
            <Row>
              <Col span={24}>
                <Button
                  onClick={() => {
                    navigate('/postjob');
                  }}
                  style={{
                    backgroundColor: '#b32800',
                    float: 'right',
                    color: '#fff',
                    margin: '15px',
                  }}
                  shape='round'
                  icon={<PlusCircleFilled />}>
                  Add Vacancy
                </Button>
              </Col>
            </Row>
            <Row
              style={{
                display: 'flex',
                marginTop: '20px',
                paddingRight: '25px',
              }}>
              {vacancies?.length > 0 ? (
                vacancies?.map((info) => {
                  return (
                    <>
                      <Col
                        xs={24}
                        md={7}
                        key={info.job_id}
                        style={{ marginTop: '20px' }}>
                        <Card key={info.job_id}>
                          <div className='ant-main'>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                textTransform: 'capitalize',
                              }}>
                              <Link to={`/job/${info.job_id}`}>
                                <Meta title={info.title} />
                              </Link>
                              <span
                                style={{
                                  color: 'darkgray',
                                  fontSize: '13.5px',
                                }}>
                                Experience: {info.experience[0]}-
                                {info.experience[1]}years
                              </span>
                            </div>
                            {info.assessment ? (
                              <p>
                                Assessment Link :{' '}
                                <span
                                  style={{
                                    diplay: 'inline-flex',
                                    alignItems: 'center',
                                  }}>
                                  <a
                                    href={`https://hiring-monk.web.app${info.assessLink}`}
                                    target='_blank'>
                                    Click To Open
                                  </a>
                                  <IconButton
                                    style={{
                                      marginLeft: '10px',
                                      border: 'none',
                                      padding: '0px',
                                    }}
                                    onClick={() =>
                                      copyTextToClipboard(
                                        `https://hiring-monk.web.app${info.assessLink}`
                                      )
                                    }>
                                    <ContentCopyIcon />
                                  </IconButton>
                                </span>
                              </p>
                            ) : (
                              ''
                            )}
                            <p>
                              Location:{' '}
                              {info.location !== null &&
                                info.location.length > 0
                                ? info.location.join(', ')
                                : ''}
                            </p>
                            <p>
                              Job Type : <i>{info.job_type}</i>
                            </p>
                            <p>
                              Salary : <i>{info.salary} LPA</i>
                            </p>
                            <div className='middle'>
                              <div className='text'>
                                <button
                                  onClick={() => {
                                    navigate(`/editjob/${info.job_id}`);
                                  }}>
                                  <img
                                    width='25'
                                    height='25'
                                    src='https://uploads-ssl.webflow.com/62455510264ca642dfb4e99e/628fca5c7f6279a057586871_edit.png'
                                    style={{
                                      color: 'blue',
                                      fontSize: '22px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </button>
                                <Popconfirm
                                  placement='bottomLeft'
                                  title='Are you sure to delete this vacancy?'
                                  onConfirm={() => handleDelete(info.job_id)}
                                  okText='Yes'
                                  cancelText='No'>
                                  <img
                                    width='25'
                                    height='25'
                                    src='https://uploads-ssl.webflow.com/62455510264ca642dfb4e99e/628fca5c067bea7c0bcfaef5_x-button.png'
                                    style={{
                                      color: 'red',
                                      fontSize: '22px',
                                      marginLeft: '7px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs={24} md={1}></Col>
                    </>
                  );
                })
              ) : (
                <Empty />
              )}
            </Row>
          </div>
        </div>
      ) : (
        <h2>Please register as a recruiter to view this content</h2>
      )}
    </Wrapper>
  );
};

export default CreateVacancy;
const Wrapper = styled.div`
	width: 99vw;
    overflow: hidden;
    .ant-tag{
        margin-top: 7px;
    }
    .ant-card-body {
        padding: 15px;
    }
    .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        bottom: 2%;
        right: 0%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }

      .ant-main:hover {
        opacity: 0.8;
      }

      .ant-main:hover .middle {
        opacity: 1;
      }

      .text button {
        background: none;
        border: none;
      }
    p {
        margin-top: 3px;
        margin: 0px;
        color: gray;
        font-weight: 400;
    }
    a, .ant-card-meta-title {
      color: blue !important;
    }
    a:hover, .ant-card-meta-title:hover {
      color: black !important;
    }
    .ant-card {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        transition: 0.15s all;
    }

    .container {
      margin-left: 220px;
      height :100%;
      padding-bottom: 50px;
      h2 {
        color: #000;
        font-size: 16px;
        input {
          color: #b32800;
          font-weight: 500;
          background: #f7f7f7;
        }
      }
    }
  }
`;
