// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA6_S5VH6rWTfXeSAyImjX3s7yff2AmyaU',
  authDomain: 'hiring-monk.firebaseapp.com',
  projectId: 'hiring-monk',
  storageBucket: 'hiring-monk.appspot.com',
  messagingSenderId: '585126779404',
  appId: '1:585126779404:web:0343cd77cf4b283e55ec29',
  measurementId: 'G-Y9761G7X0K',
  databaseURL:
    'https://hiring-monk-default-rtdb.asia-southeast1.firebasedatabase.app',
};

// Initialize Firebase
export const fire = initializeApp(firebaseConfig);
export const database = getDatabase();
const analytics = getAnalytics(fire);

const provider = new GoogleAuthProvider();
export const auth = getAuth();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      console.log(user, token);
      // window.location.reload();

      localStorage.setItem('jobs', null);
      window.location.reload();
    })
    .catch((error) => {
      // Handle Errors here.
      console.log(error.message);
    });
};

export const signInWithGoogleReload = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      console.log(user, token);
      window.location.reload();
    })
    .catch((error) => {
      // Handle Errors here.
      console.log(error.message);
    });
};

// export const

export default fire;
