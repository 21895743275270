import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Row, Col, Button, Card, Tabs, Tag } from 'antd';
import { Collapse, Empty } from 'antd';
import { getDatabase, ref, child, get } from 'firebase/database';
import ProfileModal from './profile-details(Assess)';
import Header from '../Header';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';

function sort_by_key(array, key) {
  if (array !== undefined) {
    return array.sort(function (a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return x < y ? 1 : x > y ? -1 : 0;
    });
  }
}

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const dbRef = ref(getDatabase());

const QuizCandidates = () => {
  const [loading, setLoading] = useState(false);
  const [less, setLess] = useState([]);
  const [more, setMore] = useState([]);
  const [profileId, setProfileId] = useState('');
  const [isModal, setModal] = useState(false);
  const [vacancies, setVacancies] = useState([]);
  const [job, setJob] = useState('');
  const [admin, setAdmin] = useState(false);
  const [open, setOpen] = useState('');

  useEffect(() => {
    get(child(dbRef, `monkAdmins`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().includes(localStorage['accessTokenid']))
            setAdmin(true);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const fetchProfiles = async (id) => {
    setJob(id);
    let list = [];
    await get(child(dbRef, `quiz_taken_candidates/${id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((snap) => {
            list.push(snap.val());
          });
          const prof = list;

          setLess(
            sort_by_key(
              prof.filter((item) => {
                let dat2 = new Date();
                let dat = new Date(item.date_applied);
                return (
                  Math.ceil(Math.abs(dat2 - dat) / (1000 * 60 * 60 * 24)) <= 31
                );
              }),
              'date_applied'
            )
          );

          setMore(
            sort_by_key(
              prof.filter((item) => {
                let dat2 = new Date();
                let dat = new Date(item.date_applied);
                return (
                  Math.ceil(Math.abs(dat2 - dat) / (1000 * 60 * 60 * 24)) > 31
                );
              }),
              'date_applied'
            )
          );
        } else {
          setLess([]);
          setMore([]);
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let list = [];
      await get(child(dbRef, `alljobs`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((snap) => {
              list.push(snap.val());
            });
            setVacancies(list);
          } else {
            console.log('No data available');
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setLoading(false);
    }
    fetchData();
  }, []);

  const onChange = (key) => {
    setLoading(true);
    if (key === open) setOpen('');
    else setOpen(key);
    fetchProfiles(key);
  };

  const rerender = () => {
    setLoading(true);
    fetchProfiles(job);
  };

  const columns = [
    {
      title: 'Name',
      key: 'profile',
      align: 'center',
      width: '20%',
      render: (text) => (
        <div>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <img
                src={text?.photo}
                style={{
                  borderRadius: '10px',
                  height: '35px',
                  display: 'inline',
                  marginRight: '10px',
                }}
                referrerpolicy='no-referrer'
              />
            </div>
            <div style={{ textAlign: 'left' }}>
              <div>{text?.name}</div>
            </div>
          </Row>
        </div>
      ),
    },
    {
      title: 'Date Applied',
      key: 'date',
      align: 'center',
      width: '20%',
      dataIndex: 'date_applied',
      render: (dat) => (
        <div style={{ textAlign: 'center' }}>
          {new Date(dat).toLocaleDateString('en-IN')}
        </div>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      align: 'center',
      dataIndex: 'email',
      width: '20%',
      render: (current_company) => (
        <div style={{ textAlign: 'left' }}>{current_company}</div>
      ),
    },
    {
      title: 'Score',
      key: 'score',
      align: 'center',
      width: '10%',
      render: (title) => (
        <div style={{ textAlign: 'center' }}>
          {title?.totalScore !== ''
            ? `${title?.totalScore}/${title?.maxScore}`
            : '-'}
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      dataIndex: 'status',
      width: '10%',
      render: (title) => (
        <div style={{ textAlign: 'center' }}>{title ? title : '-'}</div>
      ),
    },
    {
      title: 'View Assessment',
      key: 'view',
      align: 'center',
      width: '20%',
      dataIndex: 'uid',
      render: (uid) => (
        <div>
          {uid ? (
            <Button
              onClick={() => {
                setProfileId(uid);
                setModal(true);
              }}>
              Details
            </Button>
          ) : (
            '--'
          )}
        </div>
      ),
    },
  ];

  //   const handleShowTotal = () => {
  //     return `Total ${monk_profiles?.total_length} Profiles`;
  //   };

  //   const handlePagination = (e) => {
  //     setPageNumber(e.current);
  //     setPageSize(e.pageSize);
  //   };

  return (
    <Wrapper>
      {localStorage['accessTokenid'] && admin ? (
        <div>
          <SideMenu selectedKey={'1'} />
          <div style={{ marginLeft: '200px' }}>
            <Header />
            <div style={{ padding: '10px' }}>
              {isModal ? (
                <ProfileModal
                  visible={isModal}
                  id={profileId}
                  jobid={job}
                  rerender={rerender}
                  closeModal={() => {
                    setModal(false);
                  }}
                />
              ) : null}
              <Row>
                <Col
                  span={24}
                  style={{
                    fontSize: 30,
                    fontWeight: '550',
                    paddingLeft: '5px',
                    marginBottom: '20px',
                  }}>
                  All Jobs
                </Col>
              </Row>

              <Row
                className='tabs-style'
                style={{
                  display: 'flex',
                  paddingRight: '25px',
                }}>
                {vacancies?.length > 0
                  ? vacancies?.map((info, index) => {
                    return (
                      <Col
                        key={info.job_id}
                        md={7}
                        xs={24}
                        style={{ margin: '15px' }}>
                        <Collapse
                          activeKey={open}
                          onChange={() => onChange(info.job_id)}>
                          <Panel
                            id={info.job_id}
                            key={info.job_id}
                            header={
                              <Card key={info.job_id}>
                                <div className='ant-main'>
                                  <Link to={`/job/${info.job_id}`}>
                                    <Meta title={info.company_name} />
                                  </Link>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      textTransform: 'capitalize',
                                    }}>
                                    <span>{info.title}</span>
                                    <span
                                      style={{
                                        color: 'darkgray',
                                        fontSize: '13.5px',
                                      }}>
                                      Experience: {info.experience[0]}-
                                      {info.experience[1]}years
                                    </span>
                                  </div>
                                  <div style={{ margin: '8px 0px 5px 0px' }}>
                                    <Tag size='large' color='purple'>
                                      {info.experience[0]}-
                                      {info.experience[1]} years
                                    </Tag>
                                    <Tag
                                      color='blue'
                                      style={{ marginLeft: '2px' }}>
                                      {info.job_type}
                                    </Tag>
                                    <Tag
                                      color='cyan'
                                      style={{ marginLeft: '2px' }}>
                                      CTC - {info.salary}LPA
                                    </Tag>
                                  </div>
                                  <p>
                                    {info.location !== null &&
                                      info.location.length > 0
                                      ? info.location.join(', ')
                                      : ''}
                                  </p>
                                  {/* <div className='middle'>
                            <div className='text'>
                              <img
                                width='25'
                                height='25'
                                src='https://uploads-ssl.webflow.com/62455510264ca642dfb4e99e/628fca5c7f6279a057586871_edit.png'
                                style={{
                                  color: 'blue',
                                  fontSize: '22px',
                                  cursor: 'pointer',
                                }}
                              />
                              <Popconfirm
                                placement='bottomLeft'
                                title='Are you sure to delete this vacancy?'
                                okText='Yes'
                                cancelText='No'>
                                <img
                                  width='25'
                                  height='25'
                                  src='https://uploads-ssl.webflow.com/62455510264ca642dfb4e99e/628fca5c067bea7c0bcfaef5_x-button.png'
                                  style={{
                                    color: 'red',
                                    fontSize: '22px',
                                    marginLeft: '7px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Popconfirm>
                            </div>
                          </div>*/}
                                </div>
                              </Card>
                            }>
                            <Tabs defaultActiveKey='1'>
                              <TabPane tab=' < 30 Days' key='1'>
                                <div className='card-root'>
                                  <div
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      marginBottom: '30px',
                                    }}>
                                    <b>Total Candidates: {less?.length}</b>
                                    <p></p>
                                    <Row gutter={[24, 24]}>
                                      <Col
                                        span={24}
                                        style={{ height: 'auto' }}>
                                        <Table
                                          size='small'
                                          style={{ marginRight: '15px' }}
                                          loading={loading}
                                          columns={columns}
                                          dataSource={less}
                                          //   pagination={{
                                          //     current: monk_profiles?.pageNumber,
                                          //     pageSize: monk_profiles?.pageSize,
                                          //     total: monk_profiles?.total_length,
                                          //     showTotal: handleShowTotal,
                                          //   }}
                                          //   onChange={handlePagination}
                                          rowKey={'uid'}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </TabPane>
                              <TabPane tab=' > 30 Days' key='2'>
                                <div className='card-root'>
                                  <div
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      marginBottom: '30px',
                                    }}>
                                    <b>Total Candidates: {more?.length}</b>
                                    <p></p>
                                    <Row gutter={[24, 24]}>
                                      <Col
                                        span={24}
                                        style={{ height: 'auto' }}>
                                        <Table
                                          size='small'
                                          style={{ marginRight: '15px' }}
                                          loading={loading}
                                          columns={columns}
                                          dataSource={more}
                                          //   pagination={{
                                          //     current: monk_profiles?.pageNumber,
                                          //     pageSize: monk_profiles?.pageSize,
                                          //     total: monk_profiles?.total_length,
                                          //     showTotal: handleShowTotal,
                                          //   }}
                                          //   onChange={handlePagination}
                                          rowKey={'uid'}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </TabPane>
                            </Tabs>
                          </Panel>
                        </Collapse>
                      </Col>
                    );
                  })
                  : ''}
              </Row>
            </div>
          </div>
        </div>
      ) : (
        'You are not an admin'
      )}
    </Wrapper>
  );
};

export default QuizCandidates;

const Wrapper = styled.div`
    background-color: #fff;
    width: 99vw;
    overflow: hidden;
    .ant-tag{
        margin-top: 7px;
    }
    .ant-drawer {
        transform: none !important;
    }
    .ant-card-bordered {
      border: none;
    }
    .ant-collapse-content, .ant-collapse-content-box {
      width: 80vw;
      position: fixed;
      background: white !important;
      z-index: 10;
      left: 200px;
      right: 0px;
    }
    a, .ant-card-meta-title {
      color: blue !important;
    }
    a:hover, .ant-card-meta-title:hover {
      color: black !important;
    }
    .ant-card-body, .ant-collapse-header {
      padding: 0px !important;
    }
    .ant-main {
        padding: 10px;
    }
    .ant-main {
        width: auto;
        height: auto;
    }
    .ant-collapse-item-active {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        transition: 0.15s all;
    }
    .ant-collapse-expand-icon {
      display: none;
    }
    .ant-collapse-item-active .ant-main {
        box-shadow: 0px -2px 30px -15px rgba(0, 0, 0, 0.3) inset;
    }
    .tabs-style .ant-tabs-ink-bar {
        background: #b32800 !important;
        height: 3px !important;
        border-radius: 16px !important;
    }
    p {
        margin-top: 7px;
        margin-bottom: 0px !important;
        color: gray;
        font-weight: 400;
    }
    .container {
      margin-left: 220px;
      height :100%;
      padding-bottom: 50px;
    }
  }
`;
