import React, { useState } from 'react';
import { Dialog, DialogContent, Button as Butt } from '@mui/material';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Button, Form, Input, message } from 'antd';

const auth = getAuth();

function createAccount(em) {
  createUserWithEmailAndPassword(auth, em, 'duhd#@67nntw@#5y')
    .then(async (userCredential) => {
      // send verification mail.
      await sendEmailVerification(userCredential.user);
      await signOut(auth);

      message.info('Please verify your email using verification link');
    })
    .catch((error) => console.log(error));
}

function verifyAccount(em) {
  signInWithEmailAndPassword(auth, em, 'duhd#@67nntw@#5y')
    .then(async (userCredential) => {
      // Signed in
      await sendEmailVerification(userCredential.user);
      await signOut(auth);

      message.info('Please verify your email using verification link');
    })
    .catch((error) => console.log(error));
}

const PostJob = () => {
  const [email, setEmail] = useState('');
  const [errors, setError] = useState('');
  const [open, setOpen] = useState(false);

  const handleLogin = async () => {
    if (
      email.includes('gmail') ||
      email.includes('yahoo') ||
      email.includes('redif') ||
      email.includes('hotmail')
    ) {
      setError('Please use your organization email');
    } else {
      signInWithEmailAndPassword(auth, email, 'duhd#@67nntw@#5y')
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
          if (!user.emailVerified) verifyAccount(email);
          else window.location.reload();
        })
        .catch((error) => {
          // create account here
          if (error.code.includes('user-not-found')) {
            createAccount(email);
          }
        });
    }
    setOpen(false);
  };

  return (
    <div align='center'>
      <Butt
        className='round grad-button'
        variant='contained'
        onClick={() => setOpen(true)}>
        Post A Job
      </Butt>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='login-popup'
        fullWidth>
        <DialogContent>
          <Form labelCol={{ span: 3 }}>
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter a valid e-mail',
                },
              ]}>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              <span style={{ fontSize: '12px' }}>
                (Use professional mail ID)
              </span>
            </Form.Item>
            <p>{errors}</p>
            <Form.Item
              wrapperCol={{
                offset: 3,
                span: 21,
              }}>
              <Button className='button-default' onClick={handleLogin}>
                Submit
              </Button>
            </Form.Item>
            <p>
              We will send you a verification link to your email. Please verify your email to continue.
            </p>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostJob;
