import React, { useContext } from 'react';
import { UserContext } from '../Providers/UserProvider';
import { getAuth, signOut } from 'firebase/auth';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Grid, Avatar, Button } from '@mui/material';
import Login from './Login/Login';
import RecruiterLogin from './Login/RecruiterLogin';

const auth = getAuth();

const Header = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div>
      {localStorage['accessTokenid'] && user ? (
        <div>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  display: 'inline-flex',
                  backgroundColor: '#fff',
                  height: '60px',
                  alignItems: 'center',
                  borderBottom: '2px solid #efeded',
                }}>
                <Grid item xs={4} id='mob' style={{ display: 'flex' }}>
                  <img
                    onClick={() => navigate('/')}
                    style={{
                      float: 'center',
                      color: '#b32800',
                      cursor: 'pointer',
                    }}
                    alt='logo'
                    src='/images/logo.png'
                    className='logo'
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    color: '#3F2203',
                    display: 'flex',
                    fontFamily: 'Roboto',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <h3 className='name'>
                    Welcome{' '}
                    {user.displayName
                      ? user.displayName.substr(
                        0,
                        user.displayName.indexOf(' ')
                      )
                      : user.email
                        .split('@')[1]
                        .split('.')[0][0]
                        .toUpperCase() +
                      user.email
                        .split('@')[1]
                        .split('.')[0]
                        .slice(1)
                        .toLowerCase()}
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className='logout'
                  style={{ alignSelf: 'center' }}>
                  <div style={{ float: 'right', display: 'inline-flex' }}>
                    <Avatar
                      style={{ marginRight: '10px' }}
                      alt={user.displayName}
                      src={
                        user.photoURL ||
                        'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png'
                      }
                    />
                    <Button
                      onClick={async () => {
                        localStorage.removeItem('accessTokenid');
                        localStorage.removeItem('provider');
                        localStorage.clear();
                        await signOut(auth);
                        window.location.href = '/';
                      }}
                      className='button-default-light'
                      variant='contained'>
                      Logout
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Grid container style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <Grid item xs={12}>
            <Grid container style={{ display: 'inline-flex' }}>
              <Grid item xs={4} md={9} id='mob' style={{ display: 'flex' }}>
                <img alt='logo' src='/images/logo.png' className='logo' />
              </Grid>
              <Grid
                item
                xs={8}
                md={3}
                className='logout'
                style={{ alignSelf: 'center', float: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Login />
                  <RecruiterLogin />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Header;
