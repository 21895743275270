import React, { Fragment } from 'react';
import Header from '../Header';
import { UserContext } from '../../Providers/UserProvider';
import { useContext, useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import { Grid } from '@mui/material';
import { getDatabase, ref, child, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Tag, Card, Empty, Spin } from 'antd';
import { Col, Row } from 'antd';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const options = { year: 'numeric', month: 'short', day: 'numeric' };

const { Meta } = Card;

const dbRef = ref(getDatabase());

function sort_by_key(array, key) {
  if (array !== undefined) {
    return array.sort(function (a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return x < y ? 1 : x > y ? -1 : 0;
    });
  }
}

const JobsApplied = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let list = [];
      await get(child(dbRef, `jobs-applied/${localStorage['accessTokenid']}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((snap) => {
              list.push(snap.val());
            });
            setVacancies(sort_by_key(list, 'date_applied'));
          } else {
            console.log('No data available');
            setVacancies([]);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      setLoading(false);
    }
    fetchData();
  }, []);

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <Wrapper>
      {localStorage['accessTokenid'] &&
      localStorage['provider'] !== 'Recruiter' ? (
        <div>
          <SideMenu selectedKey={'1'} />
          <div style={{ marginLeft: '200px' }}>
            <Header />
          </div>
          <div className='container'>
            <Spin spinning={loading}>
              <Grid
                container
                style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} md={12}>
                  <h1 style={{ margin: '20px 0px 10px 15px' }}>Jobs Applied</h1>
                  <Row
                    style={{
                      display: 'flex',
                      padding: '0px 15px',
                    }}>
                    {vacancies?.length > 0 ? (
                      vacancies?.map((info, index) => {
                        return (
                          <Fragment key={info.job_id}>
                            <Col
                              xs={24}
                              md={7}
                              key={info.job_id}
                              style={{ marginTop: '20px' }}>
                              <Card
                                onClick={() => navigate(`/job/${info.job_id}`)}>
                                <div className='ant-main'>
                                  <div>
                                    <img
                                      src={info.job_details.logo}
                                      alt='image'
                                      style={{ height: '50px', width: 'auto' }}
                                    />
                                    {/* <Tag color="#ef4565">Featured</Tag> */}
                                  </div>
                                  <div style={{ marginTop: '40px' }}>
                                    <h2>{info.job_details.title}</h2>
                                    <p className='applied-info'>
                                      Applied on:{' '}
                                      {new Date(
                                        info.date_applied
                                      ).toLocaleString('en-US', options)}
                                    </p>
                                    {info.status !== undefined ? (
                                      info.status !== '' ? (
                                        <p className='applied-info2'>
                                          Assessment Status: {info.status}
                                        </p>
                                      ) : (
                                        <p className='applied-info2'>
                                          Assessment Status: Not Updated Yet
                                        </p>
                                      )
                                    ) : null}
                                    {info.recruiter_status !== undefined ? (
                                      info.recruiter_status !== '' ? (
                                        <p className='applied-info2'>
                                          Overall Status:{' '}
                                          {info.recruiter_status}
                                        </p>
                                      ) : (
                                        <p className='applied-info2'>
                                          Overall Status: Not Updated Yet
                                        </p>
                                      )
                                    ) : null}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      textTransform: 'capitalize',
                                      marginTop: '20px',
                                    }}>
                                    <div>
                                      <Tag color='purple'>
                                        {info.job_details.experience[0]}-
                                        {info.job_details.experience[1]} years
                                      </Tag>
                                      <Tag
                                        color='cyan'
                                        style={{ marginLeft: '7px' }}>
                                        {info.job_details.job_type}
                                      </Tag>
                                    </div>
                                    <IconButton
                                      style={{
                                        background: '#000',
                                        borderRadius: '50%',
                                        color: '#fff',
                                      }}>
                                      <ArrowForwardIcon fontSize='small' />
                                    </IconButton>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                            <Col xs={24} md={1}></Col>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div style={{ textAlign: 'center', width: '100%' }}>
                        <Empty />
                      </div>
                    )}
                  </Row>
                </Grid>
              </Grid>
            </Spin>
          </div>
        </div>
      ) : (
        <h2>Please register as a candidate to view this content</h2>
      )}
    </Wrapper>
  );
};

export default JobsApplied;
const Wrapper = styled.div`
	width: 100%;
    overflow: hidden;
    .ant-tag{
      margin-top: 7px;
  }
  .ant-card-body {
      padding: 25px;
  }
    .ant-card:hover {
        transform: scale(1.05)
    }
  
  p {
      margin-top: 7px;
      color: gray;
      font-weight: 400;
  }
  .ant-card {
      box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
      transition: 0.15s all;
      border-radius: 16px;
      cursor: pointer;
  }
  .ant-collapse-content p {
      color: gray;
      font-weight: 400;
      margin: 0px;
      margin-left: 5px;
  }
  .ant-collapse {
      margin-top: 10px;
  }
  .ant-collapse-header, .ant-collapse-content-box {
      padding: 0px !important;
  }
  .ant-collapse-header > div {
      display: none;
  }
  .container {
    margin-left: 220px;
    height :100%;
    padding-bottom: 50px;
    h2 {
      color: #000;
      font-size: 16px;
      input {
        color: #b32800;
        font-weight: 500;
        background: #f7f7f7;
      }
    }
  }
    a, .ant-card-meta-title {
      color: blue !important;
    }
    a:hover, .ant-card-meta-title:hover {
      color: black !important;
    }
    .ant-card {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        transition: 0.15s all;
    }
  }
`;
