import React, { useEffect, useState } from 'react';
import { Drawer, Row, Col, Select, Spin, Tabs, Button } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';
import AssessDetails from './profile-details(Assess)';
import { database } from '../../config/fire';
import { getDatabase, ref, set } from 'firebase/database';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Option } = Select;
const dbRef = ref(getDatabase());

const ProfileModal = (props) => {
  const { visible, closeModal, jobid, id, data, rerender } = props;
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState();

  useEffect(() => {
    // console.log(data);
  }, [id]);

  const handleEdit = () => {
    setStatus(data.recruiter_status);
    setEdit(true);
  };

  const handleStatus = (val) => {
    setStatus(val);
  };

  const addStatus = async () => {
    setLoading(true);
    if (data?.answers !== undefined) {
      await set(
        ref(database, `quiz_taken_candidates/${jobid}/${id}/recruiter_status`),
        status
      );
    }

    if (data?.resume !== undefined) {
      await set(
        ref(
          database,
          `monk_applied_candidates/${jobid}/${id}/recruiter_status`
        ),
        status
      );
    }

    await set(
      ref(database, `jobs-applied/${id}/${jobid}/recruiter_status`),
      status
    );

    setEdit(false);
    rerender();
    setLoading(false);
  };

  return (
    <Wrapper>
      <div>
        <Drawer
          placement='right'
          closable={true}
          visible={visible}
          width='50%'
          destroyOnClose={true}
          onClose={closeModal}
          closeIcon={<CloseCircleFilled />}
          getContainer={false}
          footer={null}
          maskClosable={true}>
          <Spin spinning={loading}>
            {data !== undefined && data ? (
              <>
                <Row>
                  <Col
                    xs={8}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={data?.photo}
                      style={{
                        borderRadius: '50%',
                        height: '130px',
                        width: '130px',
                      }}
                    />
                  </Col>
                  <Col
                    xs={16}
                    style={{ justifyContent: 'center', textAlign: 'left' }}>
                    <span
                      style={{
                        fontSize: '17px',
                        color: '#0072b1',
                        textDecoration: 'none',
                        fontWeight: '600',
                      }}>
                      <b>{data?.name}</b>
                    </span>
                    <br />
                    <p
                      style={{
                        color: 'gray',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      Current Salary :
                      <span style={{ marginLeft: '3px' }}>
                        {data?.current_salary !== undefined
                          ? `${data?.current_salary} LPA`
                          : 'Not Available'}
                      </span>
                    </p>
                    <p
                      style={{
                        color: 'gray',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      Expected Salary :
                      <span style={{ marginLeft: '3px' }}>
                        {data?.expected_salary !== undefined
                          ? `${data?.expected_salary} LPA`
                          : 'Not Available'}
                      </span>
                    </p>
                    <p
                      style={{
                        color: 'gray',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      Notice Period :
                      <span style={{ marginLeft: '3px' }}>
                        {data?.notice !== undefined
                          ? `${data?.notice} days`
                          : 'Not Available'}
                      </span>
                    </p>
                    <div>
                      {data.recruiter_status === '' || edit ? (
                        <div>
                          <Select
                            placeholder='Select Status'
                            style={{ width: 180, marginTop: '10px' }}
                            value={status}
                            size='small'
                            onChange={handleStatus}>
                            <Option value='Selected'>Selected</Option>
                            <Option value='Rejected'>Rejected</Option>
                            <Option value='On Hold'>On Hold</Option>
                          </Select>
                          <Button
                            type='primary'
                            size='small'
                            onClick={addStatus}>
                            <PlusOutlined /> Submit Status
                          </Button>
                        </div>
                      ) : (
                        <p
                          style={{
                            color: 'gray',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          Status :
                          <span style={{ marginLeft: '3px' }}>
                            {data?.recruiter_status}
                          </span>
                          <IconButton
                            style={{ padding: '0px 4px' }}
                            color='primary'
                            onClick={handleEdit}>
                            <EditIcon fontSize='small' />
                          </IconButton>
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                  {data?.answers !== undefined && data?.resume !== undefined ? (
                    <Tabs defaultActiveKey='1'>
                      <TabPane tab='Resume' key='1'>
                        <embed
                          src={`${data?.resume}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
                          width='100%'
                          height='650px'
                        />
                      </TabPane>
                      <TabPane tab='Assessment' key='2'>
                        <AssessDetails data={data} />
                      </TabPane>
                    </Tabs>
                  ) : data?.resume !== undefined ? (
                    <>
                      <h2>Resume</h2>
                      <embed
                        src={`${data?.resume}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
                        width='100%'
                        height='650px'
                      />
                    </>
                  ) : (
                    <>
                      <AssessDetails data={data} />
                    </>
                  )}
                </Row>
              </>
            ) : null}
          </Spin>
        </Drawer>
      </div>
    </Wrapper>
  );
};

ProfileModal.propTypes = {
  visible: PropTypes.any,
  id: PropTypes.any,
  jobid: PropTypes.any,
  closeModal: PropTypes.func,
  data: PropTypes.object,
  rerender: PropTypes.func,
};

export default ProfileModal;

const Wrapper = styled.div`
  background-color: #fff;
  .ant-drawer-header-title {
    justify-content: flex-end;
  }
  .ant-drawer-close {
    margin-right: 0px;
  }
  p {
    margin-bottom: 4px;
    white-space: nowrap;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
