import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Card, Col, message, Tag } from 'antd';
import { set, child, get, getDatabase, ref, update } from 'firebase/database';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { database, signInWithGoogle } from '../../config/fire';
import { UserContext } from '../../Providers/UserProvider';
import Login from '../Login/Login';

function ensureProtocol(url) {
    if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
    }
    return url;
}

const dbRef = ref(getDatabase());

const SingleJobCard = ({ info }) => {
    const [dis, setDis] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useContext(UserContext);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);

    function handleOpen() {
        setIsModalOpen(true);
    };

    function handleClose() {
        setIsModalOpen(false);
    }

    useEffect(() => {
        setLoading(true);
        get(child(dbRef, `monk_applied_candidates/${info.job_id}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    var key = Object.keys(snapshot.val());
                    if (key.includes(localStorage['accessTokenid'])) setDis(true);
                } else {
                    // console.log('No data available');
                }
            })
            .catch((error) => {
                // console.error(error);
            });
        setLoading(false);
    }, [info.job_id]);

    const handleApplyToJob = async () => {
        try {
            setLoading(true);
            get(child(dbRef, `user_filters/${localStorage['accessTokenid']}`))
                .then(async (snapshot) => {
                    if (snapshot.exists()) {
                        let data = snapshot.val();
                        if (
                            (data.experience >= info.experience[0] && data.experience <= info.experience[1]) &&
                            (data.expected_salary <= info.salary)
                        ) {
                            // console.log('You are eligible for this job');
                            const values = {};
                            values.resume = data.resume;
                            values.name = user.displayName ?? "";
                            values.email = user.email;
                            values.photo = user.photoURL
                                ? user.photoURL
                                : 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png';
                            values.uid = localStorage['accessTokenid'];

                            await set(
                                ref(database, 'candidates_info/' + localStorage['accessTokenid']),
                                values
                            );

                            const dat = new Date();
                            values.date_applied = dat.toISOString();
                            values.job_id = info.job_id;
                            values.candidate_id = localStorage['accessTokenid'];
                            values.recruiter_status = '';

                            await set(
                                ref(
                                    database,
                                    'monk_applied_candidates/' +
                                    info.job_id +
                                    '/' +
                                    localStorage['accessTokenid']
                                ),
                                values
                            );

                            values.job_details = {
                                experience: info.experience,
                                location: info.location,
                                salary: info.salary,
                                title: info.title,
                                logo: info.companyLogo,
                                job_type: info.job_type,
                            };

                            await update(
                                ref(
                                    database,
                                    'jobs-applied/' + localStorage['accessTokenid'] + '/' + info.job_id
                                ),
                                values
                            );
                            // message.success('Applied successfully');
                            window.location.href = `/assessment/${info.job_id}`;
                        } else {
                            // message.error('You are not eligible for this job');
                        }
                    }
                })
                .catch(() => {
                    console.log('No user found');
                });
        } catch {
            message.error('Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    function handleApplyButtonClicked() {
        if (user) {
            handleApplyToJob();
        } else {
            handleOpen();
        }
    }

    return (
        <Fragment>
            {loading ? <h1>Loading...</h1> :
                <Fragment>
                    <Col
                        xs={24}
                        md={7}
                        key={info.job_id}
                        style={{ marginTop: '20px' }}>
                        <Card
                        // onClick={() => navigate(`/job/${info.job_id}`)}
                        >
                            <div className='ant-main'>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <img
                                        src={info.companyLogo}
                                        alt='company_logo'
                                        style={{ height: '50px', width: 'auto' }}
                                    />

                                    {/* <Tag color="#ef4565">Featured</Tag> */}

                                    <div style={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center',
                                    }}>
                                        <button
                                            onClick={handleApplyButtonClicked}
                                            style={{
                                                background: dis ? 'green' : 'black',
                                                borderRadius: '10%',
                                                color: '#fff',
                                                height: '40px',
                                                cursor: dis ? 'not-allowed' : 'pointer',
                                            }}
                                            disabled={dis}
                                        >
                                            {dis ? 'Applied' : 'Apply'}
                                            {loading && '...'}
                                        </button>
                                        <Login.Dialog
                                            open={isModalOpen}
                                            onClose={handleClose}
                                        />
                                    </div>
                                </div>
                                <div style={{
                                    marginTop: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    textDecoration: 'none'
                                }}>
                                    <a href={ensureProtocol(info.linkedin)} style={{ color: 'inherit' }}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkedInIcon />
                                    </a>
                                    <a href={ensureProtocol(info.website)} style={{ color: 'inherit' }}
                                        target="_blank"
                                        rel="noreferrer">
                                        <LinkIcon />
                                    </a>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <h2>{info.title}</h2>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        textTransform: 'capitalize',
                                        marginTop: '40px',
                                    }}>
                                    <div>
                                        <Tag color='purple'>
                                            {info.experience[0]}-{info.experience[1]}{' '}
                                            years
                                        </Tag>
                                        <Tag color='geekblue'>
                                            {info.salary} {" "} LPA
                                        </Tag>
                                        <Tag color='cyan' style={{ marginLeft: '7px' }}>
                                            {info.location.join(', ')}
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={1}></Col>
                </Fragment>
            }
        </Fragment >
    );
};

export default SingleJobCard;