import React, { Component, createContext } from 'react';
import { database, auth } from '../config/fire';
import { ref, set } from 'firebase/database';

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    user: null,
  };

  componentDidMount = () => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        set(ref(database, 'users/' + userAuth.uid), {
          displayName: userAuth.displayName ? userAuth.displayName : '',
          email: userAuth.email,
          emailVerified: userAuth.emailVerified,
          phoneNumber: userAuth.phoneNumber ? userAuth.phoneNumber : '',
          photoURL: userAuth.photoURL
            ? userAuth.photoURL
            : 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png',
          profileType:
            userAuth.providerData[0].providerId === 'password'
              ? 'Recruiter'
              : 'Candidate',
          uid: userAuth.uid,
          premium: '',
          daysLeft: '',
        });

        localStorage.setItem('accessTokenid', userAuth.uid);
        localStorage.setItem(
          'provider',
          userAuth.providerData[0].providerId === 'password'
            ? 'Recruiter'
            : 'Candidate'
        );

        this.setState({ user: userAuth });
      }
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
