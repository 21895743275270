import { Grid } from '@mui/material';
import { Row, Spin } from 'antd';
import { child, get, getDatabase, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SingleJobCard from './SingleJobCard';

const dbRef = ref(getDatabase());

function sort_by_key(array, key) {
  if (array !== undefined) {
    return array.sort(function (a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return x < y ? 1 : x > y ? -1 : 0;
    });
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ShowJobs = ({ filters }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [areJobsinDb, setAreJobsinDb] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append('Access-Control-Allow-Origin', '*');

    var Options = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch('https://monk-mailer.cyclic.app/', Options);
  }, []);

  useEffect(() => {
    get(child(dbRef, `user_filters/${localStorage['accessTokenid']}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          setData(data);
        }
      })
      .catch(() => {
        console.log('No user found');
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchdata() {
      let list = [];
      await get(child(dbRef, `alljobs`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((snap) => {
              list.push(snap.val());
            });

            list = sort_by_key(list, 'date_updated');

            if (data) {
              list = list.filter((job) => {
                return (!data.expected_salary || job.salary >= data.expected_salary) &&
                  (!data.location || (data.location && data.location.some(loc => job.location.includes(loc)))) &&
                  (!data.experience || (job.experience && job.experience[0] <= data.experience && job.experience[1] >= data.experience));
                // &&(!data.experience_type || (data.experience_type && job.job_type === data.experience_type));
              });
            }

            setJobs(list);
            localStorage.setItem('jobs', JSON.stringify(list));
            setAreJobsinDb(true);
          } else {
            console.log('No data available');
            setAreJobsinDb(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setLoading(false);
    };
    fetchdata();
  }, [data]);

  return (
    <Wrapper>
      <center>
        <h1>Curated Recruiter Jobs</h1>
        <p
          style={{
            fontSize: '22px'
          }}

        >
          Apply for a Job, take 60mins live assessment & get hired
        </p>
      </center>
      <Spin spinning={loading}>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={10}>
            <Row
              style={{
                display: 'flex',
                marginTop: '20px',
                padding: '0px 15px',
              }}>
              {!areJobsinDb ? (
                <>
                  <p
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '16px',
                      fontWeight: '400',

                    }}

                  > There are no jobs available at the moment. Please check back later.</p>
                </>
              ) : <>
                {jobs?.length > 0 ? (
                  jobs?.map((info, index) => {
                    return (
                      <SingleJobCard
                        key={index}
                        info={info}
                      />
                    );
                  })
                ) : (
                  <div style={{
                    textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', fontSize: '16px',
                    fontWeight: '400',
                  }}>
                    <p style={{ color: 'gray' }}>
                      Oopps, seems like we don't have matching jobs as per your details (location or CTC or experience).
                      We will mail you once there is a matching job for your Profile.
                      <br /><br />
                      For further assistance talk to our team.
                    </p>
                    <WhatsappButtonLink
                      href="https://api.whatsapp.com/send/?phone=917996496999&text&type=phone_number&app_absent=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppIcon />
                      WhatsApp Us
                    </WhatsappButtonLink>
                  </div>
                )}
              </>
              }
            </Row>
          </Grid>
        </Grid>
      </Spin>
    </Wrapper >
  );
};

export default ShowJobs;
const Wrapper = styled.div`
	width: 100%;
    padding-bottom: 80px;
    overflow: hidden;
    .ant-tag{
        margin-top: 7px;
    }
    .ant-card-body {
        padding: 25px;
    }
    // .ant-card:hover {
    //     transform: scale(1.05)
    // }

    p {
        margin-top: 7px;
        color: gray;
        font-weight: 400;
    }
    .ant-card {
        box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
        // transition: 0.15s all;
        border-radius: 16px;

    }
    .ant-collapse-content p {
        color: gray;
        font-weight: 400;
        margin: 0px;
        margin-left: 5px;
    }
    .ant-collapse {
        margin-top: 10px;
    }
    .ant-collapse-header, .ant-collapse-content-box {
        padding: 0px !important;
    }
    .ant-collapse-header > div {
        display: none;
    }
    .container {
      margin-left: 220px;
      height :100%;
      padding-bottom: 50px;
      h2 {
        color: #000;
        font-size: 16px;
        input {
          color: #b32800;
          font-weight: 500;
          background: #f7f7f7;
        }
      }
    }
  }
`;

const WhatsappButtonLink = styled.a`
  background: #25d366;
  color: #fff !important;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  align-self: center;
  display: flex;
  align-items: center;
  gap: 5px;
`;

