import styles from './community.module.scss';
import { Grid } from '@mui/material';
import PopularQuestions from '../Assessments/PopularQuestions';
import Footer from './footer.component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Community() {
  return (
    <>
      <div className={styles.community}>
        <div className='container'>
          <Grid container style={{ justifyContent: 'center' }}>
            <CheckCircleOutlineIcon className='icon-green' />
          </Grid>
          <h2 className={styles.whatsOnTitle}>
            Assessment Submitted Successfully!
          </h2>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={8}>
              <p
                style={{
                  fontSize: '18px',
                  color: '#3F2203',
                  textAlign: 'center',
                  fontWeight: 400,
                  marginBottom: '20px',
                }}>
                Hope the assessment went well. We will review your answers and
                get back to you ASAP. <br /> If you have any questions please
                contact: yaswanth@recruitingmonk.com
              </p>
            </Grid>
          </Grid>
        </div>
        <div className='container'>
          <h2 className={styles.whatsOnTitle}>
            In the meantime check out the community...{' '}
          </h2>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={6} md={3} className={styles.column}>
              <div className={styles.container + ' ' + styles.privateGroupCard}>
                <div className={styles.iconContainer}>
                  <img src='/community/q-and-a.png' />
                </div>
                <div className={styles.iconName}>Q & A</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3} className={styles.column}>
              <div className={styles.container + ' ' + styles.botCard}>
                <div className={styles.iconContainer}>
                  <img src='/community/boolean-bot.png' />
                </div>
                <div className={styles.iconName}>Boolean Bot</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3} className={styles.column}>
              <div className={styles.container + ' ' + styles.eventsCard}>
                <div className={styles.iconContainer}>
                  <img src='/community/events.png' />
                </div>
                <div className={styles.iconName}>Events</div>
              </div>
            </Grid>
          </Grid>

          <PopularQuestions />

          <h2 className={styles.peopleFromText}>
            People from these brands are part of our community
          </h2>
          <div className={styles.brandShowcase}>
            <div className={styles.brands}>
              <div className={styles.brand}>
                <img src='/homepage/google.png' />
              </div>
              <div className={styles.brand}>
                <img src='/homepage/flipkart.png' />
              </div>
              <div className={styles.brand}>
                <img src='/homepage/microsoft.png' />
              </div>
              <div className={styles.brand}>
                <img src='/homepage/amazon.png' />
              </div>
              <div className={styles.brand}>
                <img src='/homepage/meesho.png' />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.getTraining}>
          <a
            role='button'
            rel='noreferrer'
            target='_blank'
            href='https://community.recruitingmonk.com/'
            className={'btn ' + styles.scheduleCallButton}>
            Explore the Community
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}
