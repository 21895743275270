import React, { Component } from 'react';
import { Dialog, DialogContent, Button, Grid } from '@mui/material';
import { signInWithGoogle } from '../../config/fire';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div align='center'>
        <Button
          className='button-default'
          variant='contained'
          size='small'
          onClick={this.handleOpen}>
          Apply for a Job
        </Button>
        <Login.Dialog
          open={this.state.open}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default Login;



Login.Dialog = function LoginDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='login-popup'
      fullWidth>
      <DialogContent>
        <Grid container className='login'>
          <Grid item xs={12}>
            <p className='head'>
              Login, take a quick <b>assesment</b> & get <b>hired</b>
            </p>
          </Grid>
          <Grid item xs={12}>
            <p></p>
          </Grid>
          <Grid item xs={12}>
            <button className='googleBtn' onClick={signInWithGoogle}>
              <img
                src='https://img.pngio.com/google-logo-png-circle-google-icon-png-white-transparent-google-logo-white-png-654_665.png'
                alt='logo'
              />
              Login With Google
            </button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};